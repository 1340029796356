import React, {useEffect, useState} from "react";
import AppTitle from "../../controls/AppTitle";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppCalendarRange from "../../controls/AppCalendarRange";
import AppSubTitle from "../../controls/AppSubTitle";
import AppBarGraphic from "../../components/graphics/AppBarGraphic";
import apiDashboard from "../../../apis/dashboard/apiDashboard";
import moment from "moment";
import apiDinner from "../../../apis/dinner/apiDinner";
import AppAreaGraphic from "../../components/graphics/AppAreaGraphic";
import appColors from "../../../config/appColors";

moment.locale('es');

function SaleDashboardsScreen({}) {

    let init = moment().startOf('isoWeek').add(1, 'day').format('YYYY-MM-DD');
    let end = moment().endOf('isoWeek').add(1, 'day').format('YYYY-MM-DD');

    const [data, setData] = useState([]);
    const [projectedData, setProjectedData] = useState([]);
    const [lines, setLines] = useState([]);
    const [initDate, setInitDate] = useState(new Date(init));
    const [endDate, setEndDate] = useState(new Date(end));

    const onChangeDates = async (changeDate) => {
        const [start, end] = changeDate;

        setEndDate(end);
        setInitDate(start);

        if (end) {
            const startFormatted = moment(start).format('YYYY-MM-DD')
            const endFormatted = moment(end).format('YYYY-MM-DD')
            GetAmountsByDayAndBranch(startFormatted, endFormatted);
            GetProjectedAmountsByDayAndBranch(startFormatted, endFormatted);
        }
    };

    useEffect(() => {
        const start = moment(initDate).format('YYYY-MM-DD');
        const end = moment(endDate).format('YYYY-MM-DD');
        GetAmountsByDayAndBranch(start, end);
        GetProjectedAmountsByDayAndBranch(start, end);
    }, []);

    const GetArrayReportDays = (start, finish) => {
        const init = moment(start);
        const end = moment(finish);
        const diffDays = end.diff(init, 'days') + 1;

        let arrayDay = []
        for (let i = 0; i < diffDays; i++) {
            let day = moment(init.format('YYYY-MM-DD'));
            day = day.add(i, 'day');
            arrayDay.push({day: day.format('dddd'), date: day.format('YYYY-MM-DD')})
        }
        return arrayDay
    }

    const GetAmountsByDayAndBranch = (initDate, endDate) => {
        apiDashboard.getAmountsByDayAndBranch(1, {
            initDate: initDate,
            endDate: endDate
        }).then((records) => {

            console.log('LOOOOG: ', records);

            if (!records || !records.data) return;

            apiDinner.getDinnersDropdown().then((dinners) => {
                setLines(dinners.data.map((dinner) => dinner.label));

                let dataElements = [];

                const arrayDays = GetArrayReportDays(initDate, endDate)

                for (let i = 0; i < arrayDays.length; i++) {
                    let day = arrayDays[i];
                    let data = {};

                    data.name = `${day.date}`;

                    for (let j = 0; j < dinners.data.length; j++) {
                        let dinner = dinners.data[j];

                        for (let k = 0; k < records.data.length; k++) {
                            let record = records.data[k];
                            if (dinner.label === record.branch && day.date === record.date)
                                data[dinner.label] = record.amount;
                            else
                                data[dinner.label] = parseFloat((data[dinner.label] ? data[dinner.label] : 0)) + 0;
                        }
                    }
                    dataElements.push(data);
                }
                setData(dataElements);
            })
        });
    }
    const GetProjectedAmountsByDayAndBranch = () => {
        const initDate = new moment().startOf('month').format("YYYY-MM-DD");
        const endDate = new moment().endOf("month").format("YYYY-MM-DD");

        apiDashboard.getProjectedAmountsByDayAndBranch(1, {
            initDate: initDate,
            endDate: endDate
        }).then((records) => {
                let AVG = 0;
                records = records.data;
                let newAmountArray = [];
                let lastValueOfMonth = 0;
                let valuesWithoutZero = records.filter(item => item.amount !== 0);

                records.forEach(item => {
                    lastValueOfMonth = parseInt(lastValueOfMonth) + parseInt(item.amount)
                });

                lastValueOfMonth = parseInt(lastValueOfMonth / valuesWithoutZero.length);

                for (let i = 0; i < records.length; i++) {
                    const currentAmount = records[i];
                    const prevAmount = records[i === 0 ? i : i - 1];
                    if (currentAmount !== prevAmount) {
                        if (moment(new Date()).diff(currentAmount.date, 'days') >= 0) {
                            if (moment(currentAmount.date) < moment(new Date())) {
                                AVG += (currentAmount.amount - prevAmount.amount);
                            }
                        }
                    }
                }

                AVG = Math.round(AVG / records.length);

                for (let i = 0; i < records.length; i++) {
                    const amount = records[i];

                    let newAmount = {
                        name: amount.date,
                        Proyectado: parseInt(amount.amount),
                    };

                    if (moment(new Date()).diff(amount.date, 'days') <= 0) {
                        newAmount.Proyectado = parseInt(lastValueOfMonth) + parseInt(AVG);
                        lastValueOfMonth = parseInt(lastValueOfMonth) + parseInt(AVG);
                    }
                    newAmountArray.push(newAmount);
                }
                setProjectedData(newAmountArray);
            }
        );
    }

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text="Gráficos de Ventas: "/>
                <BreadCrumb items={[{label: "Gráficos"}, {label: "Reporte Gráficos de Ventas", active: true}]}/>
            </div>
            <div className="col-12 card row p-4">
                <div className="col-12 text-center">
                    <AppCalendarRange label="Rango del Reporte: " startDate={initDate} endDate={endDate}
                                      onChange={onChangeDates}/>
                </div>
            </div>
            <div className="col-12 card row p-4">
                <div className="col-12 text-center">
                    <AppSubTitle text='GRÁFICO DE VENTAS DIARIAS POR SUCURSAL'/>
                    <div className="card-body col-12 row" style={{height: 1000}}>
                        <div className='col-12'>
                            <AppSubTitle text='Ventas por dia y sucursal'/>
                            <span style={{color: appColors.black_01, fontSize: 10, fontWeight: 600}}>
                                Gráfico de barras que muestra el monto de las ventas realizadas por cada día de la semana, o por los dias seleccionados, se agrupa por sucursal
                            </span>
                        </div>
                        <div className='col-12'>
                            <AppBarGraphic data={data} lines={lines}/>
                        </div>
                        <div className='col-12'>
                            <AppSubTitle text='Ventas por dia y sucursal'/>
                            <span style={{color: appColors.black_01, fontSize: 10, fontWeight: 600}}>
                                Gráfico de áreas que muestra la tendencia y curvas del monto de las ventas realizadas por cada día de la semana, o por los dias seleccionados, se agrupa por sucursal
                            </span>
                        </div>
                        <div className='col-12'>
                            <AppAreaGraphic data={data} lines={lines}/>
                        </div>
                        <div className='col-12'>
                            <AppSubTitle text='Proyección de ventas para todo el mes'/>
                            <span style={{color: appColors.black_01, fontSize: 10, fontWeight: 600}}>
                                Gráfico que muestra el monto proyectado para los dias restantes del mes actual, basado en las ventas históricas del mes, el monto de las ventas se agrupa por día.
                            </span>
                        </div>
                        <div className='col-12'>
                            <AppAreaGraphic data={projectedData} lines={['Proyectado']}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaleDashboardsScreen;
