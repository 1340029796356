function AppInput({
                      onChange,
                      value,
                      label,
                      placeholder,
                      errors = [],
                      type = "text",
                      defaultValue = null,
                      onKeyPress,
                      disabled = false,
                      name = null
                  }) {
    return (
        <div className="form-group">
            <label className="" htmlFor="input">
                {label}
            </label>
            <input
                key={name}
                id="input"
                type={type}
                disabled={disabled}
                onChange={onChange}
                value={value}
                onKeyPress={onKeyPress}
                defaultValue={defaultValue}
                className="form-control"
                placeholder={placeholder}
            />
            {errors.map((error, index) => {
                return (
                    <span key={index} className="text-danger small">
						{error}
					</span>
                );
            })}
        </div>
    );
}

export default AppInput;
