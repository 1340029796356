import apiBase from "../apiBase";

export default {
    getAmountsByDayAndBranch(page = 1, data = {}) {
        const initDate = data.initDate ? data.initDate : "";
        const endDate = data.endDate ? data.endDate : "";
        const work_unit = data.work_unit ? data.work_unit : "";
        const contractor = data.contractor ? data.contractor : "";
        const service = data.service ? data.service : "";
        const dinner_point = data.dinner_point ? data.dinner_point : "";

        const url =
            "api/sale/dashboard/getAmountsByDayAndBranch?page=" +
            page +
            "&&initDate=" +
            initDate +
            "&&endDate=" +
            endDate +
            "&&work_unit=" +
            work_unit +
            "&&contractor=" +
            contractor +
            "&&service=" +
            service +
            "&&dinner_point=" +
            dinner_point;

        return apiBase.get(url);
    },
    getProjectedAmountsByDayAndBranch(page = 1, data = {}) {
        const initDate = data.initDate ? data.initDate : "";
        const endDate = data.endDate ? data.endDate : "";
        const work_unit = data.work_unit ? data.work_unit : "";
        const contractor = data.contractor ? data.contractor : "";
        const service = data.service ? data.service : "";
        const dinner_point = data.dinner_point ? data.dinner_point : "";

        const url =
            "api/sale/dashboard/getProjectedAmountsByDayAndBranch?page=" +
            page +
            "&&initDate=" +
            initDate +
            "&&endDate=" +
            endDate +
            "&&work_unit=" +
            work_unit +
            "&&contractor=" +
            contractor +
            "&&service=" +
            service +
            "&&dinner_point=" +
            dinner_point;

        return apiBase.get(url);
    },
    getProductsBestSelling(page = 1, data = {}) {
        const initDate = data.initDate ? data.initDate : "";
        const endDate = data.endDate ? data.endDate : "";
        const work_unit = data.work_unit ? data.work_unit : "";
        const contractor = data.contractor ? data.contractor : "";
        const service = data.service ? data.service : "";
        const dinner_point = data.dinner_point ? data.dinner_point : "";

        const url =
            "api/sale/dashboard/getProductsBestSellingBetweenDates?page=" +
            page +
            "&&initDate=" +
            initDate +
            "&&endDate=" +
            endDate +
            "&&work_unit=" +
            work_unit +
            "&&contractor=" +
            contractor +
            "&&service=" +
            service +
            "&&dinner_point=" +
            dinner_point;

        return apiBase.get(url);
    },
    getCategoriesBestSelling(page = 1, data = {}) {
        const initDate = data.initDate ? data.initDate : "";
        const endDate = data.endDate ? data.endDate : "";
        const work_unit = data.work_unit ? data.work_unit : "";
        const contractor = data.contractor ? data.contractor : "";
        const service = data.service ? data.service : "";
        const dinner_point = data.dinner_point ? data.dinner_point : "";

        const url =
            "api/sale/dashboard/getCategoriesBestSellingBetweenDates?page=" +
            page +
            "&&initDate=" +
            initDate +
            "&&endDate=" +
            endDate +
            "&&work_unit=" +
            work_unit +
            "&&contractor=" +
            contractor +
            "&&service=" +
            service +
            "&&dinner_point=" +
            dinner_point;

        return apiBase.get(url);
    },
    getProductsBestProfit(page = 1, data = {}) {
        const initDate = data.initDate ? data.initDate : "";
        const endDate = data.endDate ? data.endDate : "";
        const work_unit = data.work_unit ? data.work_unit : "";
        const contractor = data.contractor ? data.contractor : "";
        const service = data.service ? data.service : "";
        const dinner_point = data.dinner_point ? data.dinner_point : "";

        const url =
            "api/sale/dashboard/getProductsBestProfitBetweenDates?page=" +
            page +
            "&&initDate=" +
            initDate +
            "&&endDate=" +
            endDate +
            "&&work_unit=" +
            work_unit +
            "&&contractor=" +
            contractor +
            "&&service=" +
            service +
            "&&dinner_point=" +
            dinner_point;

        return apiBase.get(url);
    },
}