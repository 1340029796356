import React, {useEffect, useState} from "react";
import apiCompany from "../../../apis/company/apiCompany";
import apiDinner from "../../../apis/dinner/apiDinner";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import {useHistory, useLocation} from "react-router";

function DinnerCreateForm() {
    const location = useLocation();
    const history = useHistory();

    const [dinner, setDinner] = useState(location.state)
    const [company, setCompany] = useState({});
    const [loading, setLoading] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [errorsForm, setErrorsForm] = useState({});

    const UpdateDinner = async () => {

        setLoading(true);
        const response = await apiDinner.updateDinner(dinner);
        setLoading(false);

        if (response.ok) {
            history.goBack();
            AppAlert.SimpleAlert(response.message);
            setErrorsForm({});
        } else {
            setErrorsForm(response);
        }
    };

    const GetCompanies = async (company_id) => {
        const response = await apiCompany.getCompaniesDropdown();
        if (response.ok) {
            const company = response.data.find((x) => x.value === company_id);
            setCompany(company);
            setCompanyList(response.data);
        }
    };

    useEffect(() => {
        (async () => {
            await GetCompanies(location.state.company_id);
        })()
    }, []);

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text="Edición de Sucursal"/>
                <BreadCrumb items={[{label: "Sucursales"}, {label: "Edición de Sucursal", active: true}]}/>
            </div>
            <div className="card col-12">
                <div className="row card-body col-12">
                    <div className="col-12">
                        <AppInput
                            defaultValue={dinner.name || ''}
                            label="Nombre del Sucursal"
                            type="text"
                            onChange={({target}) => setDinner({...dinner, name: target.value})}
                            placeholder="Nombre del Sucursal"
                            errors={errorsForm.name}
                        />
                    </div>
                    <div className="col-8">
                        <AppInput
                            defaultValue={dinner.address || ''}
                            label="Dirección"
                            type="text"
                            onChange={({target}) => setDinner({...dinner, address: target.value})}
                            placeholder="Dirección de la sucursal"
                            errors={errorsForm.address}
                        />
                    </div>
                    <div className="col-4">
                        <AppInput
                            defaultValue={dinner.code || ''}
                            label="RUC:"
                            type="text"
                            onChange={({target}) => setDinner({...dinner, code: target.value})}
                            placeholder="Ruc de la Sucursal"
                            errors={errorsForm.code}
                        />
                    </div>
                    <div className="col-6">
                        <AppInput
                            defaultValue={dinner.phone || ''}
                            label="Teléfono:"
                            type="text"
                            onChange={({target}) => setDinner({...dinner, phone: target.value})}
                            placeholder="Teléfono de la sucursal"
                            errors={errorsForm.phone}
                        />
                    </div>
                    <div className="col-6">
                        <AppSelect
                            value={company || {}}
                            label="Compañía"
                            items={companyList.length === 0 ? [] : companyList}
                            onChange={(item) => setDinner({...dinner, company_id: item?.value})}
                            errors={errorsForm.company_id}
                        />
                    </div>
                    <div className="col-12 text-center my-4">
                        <button className="btn btn-primary" onClick={UpdateDinner}>
                            Editar Comedor
                        </button>
                    </div>
                </div>
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}

export default DinnerCreateForm;
