import React, {useEffect, useState} from "react";
import AppAlert from "../../common/AppAlert";
import AppSelect from "../../controls/AppSelect";
import AppLoading from "../../common/AppLoading";
import AppTitle from "../../controls/AppTitle";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import apiProduct from "../../../apis/product/apiProduct";
import AppSubTitle from "../../controls/AppSubTitle";
import helperSession from "../../../helpers/helperSession";
import appAlert from "../../common/AppAlert";
import AppInput from "../../controls/AppInput";
import AppIcon from "../../controls/AppIcon";
import apiDinner from "../../../apis/dinner/apiDinner";
import moment from "moment";
import {useHistory} from "react-router-dom";

function SaleCreateScreen() {
    const history = useHistory();

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [productList, setProductList] = useState([]);
    const [branches, setBranches] = useState([]);
    const [senderDate, setSenderDate] = useState(moment().format('YYYY-MM-DD'));
    const [referenceCode, setReferenceCode] = useState('');
    const [receiptBranch, setReceiptBranch] = useState({});
    const [relocationState, setRelocationState] = useState({label: 'Iniciado', value: '1'});
    const [productListSelected, setProductListSelected] = useState({items: []});

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        GetAllProducts();
        GetBranches();
        return () => {
        };
    }, []);

    const GetAllProducts = async () => {
        let response = await apiProduct.getProductsWithStockDropdown();

        if (response.ok) {
            setProductList(response.data);
        }
    };

    const GetBranches = async () => {
        let response = await apiDinner.getDinnersDropdown();
        if (response.ok) {
            setBranches(response.data);
        }
    };

    const CreateRelocation = async () => {

        const sale = {
            init_date: moment(senderDate).format('YYYY-MM-DD'),
            sender_user_id: helperSession.getCurrentUser().id,
            code: referenceCode,
            products: productListSelected.items,
            receipt_branch: receiptBranch.value,
            state: relocationState.value,
        };

        setLoading(true);
        const response = await apiProduct.createRelocation(sale);
        setLoading(false);

        if (response.ok) {
            AppAlert.AlertTopLeft(`${response.message}`, 'success');
            history.goBack()
        } else {
            setErrors(response);
        }
    };


    const onSetProductListSelected = (products = []) => {
        let productsList = products.map((product) => {
                let foundedElement = productListSelected.items.find(x => x.id === product.value);
                if (foundedElement) {
                    return {
                        name: product.label,
                        id: product.value,
                        price: product.price,
                        unit_price: product.price,
                        stock: product.stock,
                        quantity: foundedElement.quantity
                    }
                } else {
                    return {
                        name: product.label,
                        id: product.value,
                        price: product.price,
                        unit_price: product.price,
                        stock: product.stock,
                        quantity: 1
                    }
                }
            }
        );
        setProductListSelected({...productListSelected, items: productsList});
        let tempQuantity = 0;
        productsList.map(x => {
            tempQuantity += parseInt(x.quantity);
        })
        setTotalQuantity(tempQuantity);
    };

    const onInputQuantityChange = (product_id, value) => {
        let list = productListSelected.items;
        const product = list.find((x) => x.id === product_id);

        if (product) {
            const productIndex = list.findIndex((x) => x.id === product_id);

            if (parseInt(value) > product.stock) {
                if ((parseInt(value) - product.stock) === 1)
                    product.quantity = product.stock.toString();
                else {
                    product.quantity = value.toString();
                }
            } else {
                product.quantity = value.toString();
            }

            list.splice(productIndex, 1, product);
            setProductListSelected({...productListSelected, items: list});
            let tempQuantity = 0;
            productListSelected.items.map(x => {
                tempQuantity += parseInt(x.quantity)
            })
            setTotalQuantity(tempQuantity);
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text="Registro de traslados:"/>
                <AppBreadCrumb items={[{label: "Traslados"}, {label: "Registro de traslados:", active: true}]}/>
            </div>

            <div className='row col-12 justify-content-center align-items-center'>
                <div className="col-md-3 shadow p-4">
                    <AppInput label='Referencia:' placeholder='Código de referencia...'
                              onChange={({target}) => setReferenceCode(target.value)}
                              errors={errors.reference_code}
                    />
                </div>

                <div className="col-md-4 shadow p-4 mx-2">
                    <AppSelect
                        label="Para almacén:"
                        onChange={(branch) => setReceiptBranch(branch)}
                        items={branches}
                        errors={errors.receipt_branch}
                    />
                </div>

                <div className="col-md-4 shadow p-4">
                    <AppInput
                        label='Estado:'
                        placeholder='Estado ...'
                        name='state'
                        value={relocationState && relocationState?.label}
                        disabled
                    />
                </div>
            </div>

            <div className='row col-12 justify-content-center align-items-center'>
                <div className="col-md-4 shadow p-4">
                    <AppInput label='Fecha de traslado:' type='date' defaultValue={senderDate}
                              onChange={({target}) => setSenderDate(moment(target.value).format('YYYY-MM-DD'))} errors={errors.sender_date}/>
                </div>

                <div className="col-md-7 ml-1 shadow p-4">
                    <AppSelect isMulti={true} closeMenuOnSelect={false} label="Productos a escoger: "
                               onChange={onSetProductListSelected}
                               errors={['(Sólo se mostrarán los productos con stock y que no hayan vencido)']}
                               items={productList}/>

                </div>
            </div>

            {/* ************ PRODUCTS ************/}
            <div className="col-12 row shadow">
                <div className="row card-body justify-content-center align-items-center">
                    <div className='col-12 flex-grow-1 mb-2'>
                        <AppSubTitle text="PRODUCTOS EN TRASLADO: "/>
                    </div>

                    <div className="row col-12">
                        <div className="px-4 col-md-12 shadow">
                            <AppSubTitle text="Productos escogidos: "/>
                            <div className="table-sm table-response-md">
                                <table className="table w-100 table-sm small">
                                    <thead className="table-info">
                                    <tr>
                                        <th>Producto</th>
                                        <th>Cantidad</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {productListSelected.items.map((product, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{maxWidth: '50%', fontSize: 12}}>{product.name}</td>
                                                <td style={{width: 100, fontSize: 12}}>
                                                    <input
                                                        style={{maxWidth: 50, fontSize: 11, textAlign: 'center'}}
                                                        type="number"
                                                        onChange={(event) => {
                                                            let {target} = event;
                                                            if ((target.value > 0) && (target.value <= parseInt(product.stock))) {
                                                                onInputQuantityChange(product.id, target.value)
                                                            } else {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                return appAlert.SimpleAlert(`No existe ${target.value} productos en stock para ${product.name}, por el momento tenemos ${product.stock} und.`, 'error');
                                                            }
                                                        }}
                                                        value={product.quantity}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td style={{maxWidth: '50%', fontSize: 14, fontWeight: 'bold'}}>Total de
                                            productos a trasladar:
                                        </td>
                                        <td style={{width: 100, fontSize: 12, fontWeight: 'bold'}}>
                                            {totalQuantity} unidades.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr/>
                            <div className="text-center w-100 p-4">
                                <button className="btn btn-primary btn-sm p-2" onClick={CreateRelocation}>
                                    <div className='row px-3'>
                                        <span>INICIAR TRASLADO</span>
                                        <AppIcon iconName='truck-moving' color='white'></AppIcon>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}

export default SaleCreateScreen;
