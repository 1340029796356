import React, {useEffect, useState} from "react";
import AppTitle from "../../controls/AppTitle";
import AppCalendarRange from "../../controls/AppCalendarRange";
import AppLoading from "../../common/AppLoading";
import apiWorkUnit from "../../../apis/workUnit/apiWorkUnit";
import BreadCrumb from "../../common/AppBreadCrumb";
import apiSale from "../../../apis/sale/apiSale";
import moment from "moment";
import TableToExcel from "../../components/TableToExcel";

function ProductReportScreen() {
    let init = moment().startOf('isoWeek').add(1, 'day').format('YYYY-MM-DD');
    let end = moment().endOf('isoWeek').add(1, 'day').format('YYYY-MM-DD');

    const headers = ["Fecha", "Usuario", "Persona", "Caja", "Precio", "Cantidad", "Producto"];
    const values = ["saled_date", "email", "person_name", "dinner_point", "price", "quantity", "product"];
    const filterBy =
        [
            {label: "Fecha", value: "saled_date"},
            {label: "Email", value: "email"},
            {label: "Cliente", value: "person_name"},
            {label: "Caja", value: "dinner_point"},
            {label: "Product", value: "product"}
        ];

    const [initDate, setInitDate] = useState(new Date(init));
    const [endDate, setEndDate] = useState(new Date(end));

    const [workUnitList, setWorkUnitList] = useState([]);

    const [totalSales, setTotalSales] = useState({});
    const [loading, setLoading] = useState(false);

    const onChangeDates = async (changeDate) => {
        const [start, end] = changeDate;

        setEndDate(end);
        setInitDate(start);

        if (end) {
            const startFormatted = moment(start).format('YYYY-MM-DD')
            const endFormatted = moment(end).format('YYYY-MM-DD')
            GetSalesToReport(workUnitList, startFormatted, endFormatted);
        }
    };

    useEffect(() => {
        (async () => {
            GetWorkUnits().then((response) => {
                const start = moment(initDate).format('YYYY-MM-DD');
                const end = moment(endDate).format('YYYY-MM-DD');
                GetSalesToReport(response, start, end);
            });
        })();
        return () => {
        };
    }, []);

    const GetWorkUnits = async () => {
        setLoading(true);
        const response = await apiWorkUnit.getWorkUnitsDropdown();
        setLoading(false);
        if (response.ok) {
            setWorkUnitList(response.data);
            return response.data;
        }
    };

    const GetSalesToReport = async (workUnits = [], start, end) => {
        setLoading(true);
        for (let index = 0; index < workUnits.length; index++) {
            const workUnit = workUnits[index];
            await GetFilterSales(1, workUnit, start, end);
        }
        setLoading(false);
    };

    const GetFilterSales = async (page = 1, workUnit, start, end) => {
        const data = {
            work_unit: workUnit.value,
            initDate: start,
            endDate: end,
        };

        const response = await apiSale.getSalesDetailedToReport(page, data);

        if (response.ok) {
            let previousState = totalSales;
            previousState[workUnit.label] = response.data;
            setTotalSales(previousState);
        }
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <AppTitle text="Reporte de Productos: "/>
                <BreadCrumb items={[{label: "Reporte"}, {label: "Reporte de Productos", active: true}]}/>
            </div>
            <div className="col-md-12 card">
                <div className="card-body row">
                    <div className="col-md-12 text-center my-5">
                        <AppCalendarRange label="Rango del Reporte: " startDate={initDate} endDate={endDate}
                                          onChange={onChangeDates}/>
                    </div>
                </div>
            </div>
            {workUnitList.map((workUnit, index) => {
                return (
                    <div key={index} className="col-12">
                        <AppTitle text={"Productos vendidos: "}/>
                        <TableToExcel
                            id={workUnit.label + 'product'}
                            headers={headers}
                            filterBy={filterBy}
                            values={values}
                            rows={totalSales[workUnit.label] ? totalSales[workUnit.label] : []}
                        />
                    </div>
                );
            })}
            <AppLoading visible={loading}/>
        </div>
    );
}

export default ProductReportScreen;
