import React, {useEffect, useState} from "react";
import AppTitle from "../../controls/AppTitle";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppCalendarRange from "../../controls/AppCalendarRange";
import AppSubTitle from "../../controls/AppSubTitle";
import apiDashboard from "../../../apis/dashboard/apiDashboard";
import moment from "moment";
import AppPieGraphic from "../../components/graphics/AppPieGraphic";
import appColors from "../../../config/appColors";

function ProductDashboardsScreen({}) {

    let init = moment().startOf('isoWeek').add(1, 'day').format('YYYY-MM-DD');
    let end = moment().endOf('isoWeek').add(1, 'day').format('YYYY-MM-DD');

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [profits, setProfits] = useState([]);
    const [initDate, setInitDate] = useState(new Date(init));
    const [endDate, setEndDate] = useState(new Date(end));

    const onChangeDates = async (changeDate) => {
        const [start, end] = changeDate;

        setEndDate(end);
        setInitDate(start);

        if (end) {
            const startFormatted = moment(start).format('YYYY-MM-DD')
            const endFormatted = moment(end).format('YYYY-MM-DD')
            ProductsBestSelling(startFormatted, endFormatted);
            CategoriesBestSelling(startFormatted, endFormatted);
            ProfitBestSelling(startFormatted, endFormatted);
        }
    };

    useEffect(() => {
        const start = moment(initDate).format('YYYY-MM-DD');
        const end = moment(endDate).format('YYYY-MM-DD');
        ProductsBestSelling(start, end);
        CategoriesBestSelling(start, end);
        ProfitBestSelling(start, end);
    }, []);

    const ProductsBestSelling = (initDate, endDate) => {
        apiDashboard.getProductsBestSelling(1, {
            initDate: initDate,
            endDate: endDate
        }).then((records) => {
            const datFormatted = records.data.map((record) => {
                return {name: record.name, value: parseFloat(record.value)}
            });
            setProducts(datFormatted);
        });
    }

    const CategoriesBestSelling = (initDate, endDate) => {
        apiDashboard.getCategoriesBestSelling(1, {
            initDate: initDate,
            endDate: endDate
        }).then((records) => {
            const datFormatted = records.data.map((record) => {
                return {name: record.name, value: parseFloat(record.value)}
            });
            setCategories(datFormatted);
        });
    }

    const ProfitBestSelling = (initDate, endDate) => {
        apiDashboard.getProductsBestProfit(1, {
            initDate: initDate,
            endDate: endDate
        }).then((records) => {
            const datFormatted = records.data.map((record) => {
                return {name: record.name, value: parseFloat(record.value)}
            });
            setProfits(datFormatted);
        });
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <AppTitle text="Gráficos de Ventas: "/>
                <BreadCrumb items={[{label: "Gráficos"}, {label: "Reporte Gráficos de Ventas", active: true}]}/>
            </div>
            <div className="col-12 card row p-4">
                <div className="col-12 text-center">
                    <AppCalendarRange label="Rango del Reporte: " startDate={initDate} endDate={endDate}
                                      onChange={onChangeDates}/>
                </div>
            </div>
            <div className="col-12 card row p-4">
                <div className="col-12 text-center">
                    <AppSubTitle text='PRODUCTOS VENDIDOS POR SUCURSAL'/>
                    <div className="card-body col-12 row">
                        <div className='col-4'>
                            <AppSubTitle text='Top 5 productos más vendidos'/>
                            <span style={{color: appColors.black_01, fontSize: 10, fontWeight: 600}}>
                                Muestra los 5 productos más vendidos entre las fechas seleccionadas
                                y el porcentaje respecto a las ventas totales.
                            </span>
                        </div>
                        <div className='col-4'>
                            <AppSubTitle text='Top 5 categorías más vendidas'/>
                            <span style={{color: appColors.black_01, fontSize: 10, fontWeight: 600}}>
                                Muestra las 5 categorías más vendidas entre las fechas seleccionadas
                                y el porcentaje respecto a las ventas totales.
                            </span>
                        </div>
                        <div className='col-4'>
                            <AppSubTitle text='Top 5 productos con mayor ganancia'/>
                            <span style={{color: appColors.black_01, fontSize: 10, fontWeight: 600}}>
                                Muestra los 5 productos con la mayor ganancia en ventas para las fechas seleccionadas
                                y el porcentaje al total.
                            </span>
                        </div>
                        <div className={'p-1 col-12 row'} style={{height: 500, width: "100%"}}>
                            <div className='col-4'>
                                <AppPieGraphic data={products}/>
                            </div>
                            <div className='col-4'>
                                <AppPieGraphic data={categories}/>
                            </div>
                            <div className='col-4'>
                                <AppPieGraphic data={profits}/>
                            </div>
                        </div>
                        <br/>
                        <div className='col-12 w-100 row'>
                            <div className='col-4 p-4'>
                                <table className='text-left w-100'>
                                    <thead style={{fontWeight: 600}}>Detalle de productos:</thead>
                                    <tbody className='col-4'>
                                    {products.map((product, index) => {
                                        return <tr key={index}>
                                            <th style={{fontSize: 10}}>{product.name} </th>
                                            <th style={{fontSize: 10}}>{product.value} und. </th>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>

                            <div className='col-4 p-4'>
                                <table className='text-left w-100'>
                                    <thead style={{fontWeight: 600}}>Detalle de categorías:</thead>
                                    <tbody className='col-4'>
                                    {categories.map((category, index) => {
                                        return <tr key={index}>
                                            <th style={{fontSize: 10}}>{category.name} </th>
                                            <th style={{fontSize: 10}}>{category.value} und.</th>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>

                            <div className='col-4 p-4'>
                                <table className='text-left w-100'>
                                    <thead style={{fontWeight: 600}}>Detalle de ganancias:</thead>
                                    <tbody className='col-4'>
                                    {profits.map((profit, index) => {
                                        return <tr key={index}>
                                            <th style={{fontSize: 10}}>{profit.name} </th>
                                            <th style={{fontSize: 10}}>s/ {profit.value} </th>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDashboardsScreen;
