import {Surface, Symbols} from "recharts";
import appColors from "../../../../config/appColors";
import React from "react";

export default {

    CustomizedTooltip({active, payload, label}) {
        if (active && payload && payload.length) {
            return (
                <div style={{background: appColors.blue_10, padding: 10, opacity: 0.75}}>
                    <span style={{
                        fontSize: 10,
                        color: appColors.blue_02,
                        fontWeight: 600
                    }}>{`${label}`}</span>
                    <br/>
                    {payload.map((item, index) => {
                        return (
                            <div key={index}>
                                <span style={{
                                    fontSize: 10,
                                    color: appColors.blue_02,
                                    fontWeight: 600
                                }}>{item.name?.toString().toUpperCase()} : </span>
                                <span style={{
                                    fontSize: 10,
                                    fontWeight: 600,
                                    color: appColors.blue_01
                                }}>{item.value}</span>
                            </div>
                        )
                    })}
                </div>
            );
        }

        return null;
    },

    CustomizedLabel({x, y, stroke, value}) {
        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={8} textAnchor="small">
                {value}
            </text>
        );
    },

    CustomizedLegend({payload}) {
        return (
            <div>
                {
                    payload.map(({dataKey, color}, index) => {
                        return (
                            <span key={index}>
                              <Surface width={10} height={10} viewBox="0 0 10 10" onClick={() => {
                              }}>
                                <Symbols cx={5} cy={5} type="circle" size={50} fill={color}/>
                              </Surface>
                            <span style={{color: color, fontSize: 12, margin: 4, padding: 4}}>{dataKey}</span>
                        </span>
                        )
                    })
                }
            </div>
        );
    },


    CustomizedLegendPieChart({payload}) {
        return (
            <div className='text-left pl-4'>
                {
                    payload.map(({dataKey, color, value}, index) => {
                        return (
                            <div key={index}>
                                <span>
                                  <Surface width={10} height={10} viewBox="0 0 10 10" onClick={() => {
                                  }}>
                                    <Symbols cx={5} cy={5} type="circle" size={50} fill={color}/>
                                  </Surface>
                                <span style={{color: color, fontSize: 12, margin: 4, padding: 4}}>{value}</span>
                                </span>
                                <br/>
                            </div>
                        )
                    })
                }
            </div>
        );
    },

    AxisCustomX({x, y, stroke, payload}) {
        return (
            <text x={x} y={y} dy={20} textAnchor="middle" fill={appColors.blue_02} fontSize={10}>
                {payload.value}
            </text>
        );
    },

    AxisCustomY({x, y, stroke, payload}) {
        return (
            <text x={x} y={y} dx={-5} textAnchor="end" fill={appColors.blue_02} fontSize={10}>
                {payload.value}
            </text>
        );
    }
}