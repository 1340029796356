import apiBase from "../apiBase";

export default {
    // SALE
    createSale(sale) {
        const url = "api/sale/create";
        return apiBase.post(url, sale);
    },
    updateSale(sale) {
        const url = "api/sale/update";
        return apiBase.put(url, sale);
    },
    deleteSale(sale_id) {
        const url = "api/sale/delete/" + sale_id;
        return apiBase.delete(url);
    },
    cancelSale(sale_id) {
        const url = "api/sale/cancel/" + sale_id;
        return apiBase.delete(url);
    },
    detailedSale(sale_id) {
        const url = "api/sale/details/" + sale_id;
        return apiBase.get(url);
    },
    getSalesPaginated(page = 1, filters = "") {
        if (page < 1) page = 1;
        const url = "api/sale/getSalesPaginated?page=" + page + "&&filters=" + filters;
        return apiBase.get(url);
    },
    getSalesToCashDeskReport(page = 1, data = {}) {
        const initDate = data.initDate ? data.initDate : "";
        const endDate = data.endDate ? data.endDate : "";

        const url =
            "api/sale/getSalesToCashReport?page=" +
            page +
            "&&initDate=" +
            initDate +
            "&&endDate=" +
            endDate;

        return apiBase.get(url);
    },
    getSalesToReport(page = 1, data = {}) {

        const initDate = data.initDate ? data.initDate : "";
        const endDate = data.endDate ? data.endDate : "";
        const work_unit = data.work_unit ? data.work_unit : "";
        const contractor = data.contractor ? data.contractor : "";
        const service = data.service ? data.service : "";
        const dinner_point = data.dinner_point ? data.dinner_point : "";

        const url =
            "api/sale/getSalesToReport?page=" +
            page +
            "&&initDate=" +
            initDate +
            "&&endDate=" +
            endDate +
            "&&work_unit=" +
            work_unit +
            "&&contractor=" +
            contractor +
            "&&service=" +
            service +
            "&&dinner_point=" +
            dinner_point;

        return apiBase.get(url);
    },
    getSalesDetailedToReport(page = 1, data = {}) {

        const initDate = data.initDate ? data.initDate : "";
        const endDate = data.endDate ? data.endDate : "";
        const work_unit = data.work_unit ? data.work_unit : "";
        const contractor = data.contractor ? data.contractor : "";
        const service = data.service ? data.service : "";
        const dinner_point = data.dinner_point ? data.dinner_point : "";

        const url =
            "api/sale/getSalesDetailedToReport?page=" +
            page +
            "&&initDate=" +
            initDate +
            "&&endDate=" +
            endDate +
            "&&work_unit=" +
            work_unit +
            "&&contractor=" +
            contractor +
            "&&service=" +
            service +
            "&&dinner_point=" +
            dinner_point;

        return apiBase.get(url);
    },
    getSalesDropdown(page = 1, names = "") {
        if (page < 1) page = 1;
        const url = "api/sale/getSalessDropdown?page=" + page + "&&names=" + names;
        return apiBase.get(url);
    },
};
