import React, { useContext, useState } from "react";
import LoginService from "../../../apis/authenticate/apiLogin";
import AuthContext from "../../../context/context";
import AppInput from "../../controls/AppInput";
import AppLoading from "../../common/AppLoading";

function LoginScreen() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const authContext = useContext(AuthContext);

	const SubmitLogin = async () => {
		setLoading(true);
		let response = await LoginService.authenticate(username, password);
		setLoading(false);

		if (response.ok) {
			authContext.setUserLogged(response.data);
			localStorage.setItem("userLogged", JSON.stringify(response.data));
		} else {
			setErrors(response);
		}
	};

	const OnEnterPress = async (event) => {
		if (event.key === "Enter") {
			SubmitLogin();
		}
	};

	return (
		<div className="container d-flex justify-content-center mt-5">
			<div className="card shadow pt-3">
				<small className="text-center">
					<b>INICIO DE SESIÓN</b>
				</small>
				<hr />
				<img src="../imgs/avatar-man.png" style={{ width: 100, alignSelf: "center" }} className="card-img-top shadow rounded-circle" />
				<hr />
				<div className="card-body">
					<div className="row">
						<div className="col-12 mb-3">
							<AppInput
								label="Correo Electrónico:"
								placeholder="anything@gmail.com.pe"
								onChange={({ target }) => setUsername(target.value)}
								errors={errors.email}
							/>
						</div>
						<div className="col-12 mb-3">
							<AppInput
								label="Contraseña:"
								placeholder="***********"
								type="password"
								onKeyPress={OnEnterPress}
								onChange={({ target }) => setPassword(target.value)}
								errors={errors.password}
							/>
						</div>
						<div className="col-12">
							<button className="btn btn-dark m-auto w-100" onClick={SubmitLogin}>
								Ingresar
							</button>
						</div>
					</div>
				</div>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default LoginScreen;
