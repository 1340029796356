import React, {useEffect, useState} from "react";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import apiBase from "../../../apis/apiBase";
import {useLocation} from "react-router";
import AppInput from "../../controls/AppInput";
import AppCalendar from "../../controls/AppCalendar";
import helperDate from "../../../helpers/helperDate";

function GenericEditFormScreen({moduleName = 'Registro', registerProperties = [], editURL = '/', detailURL = ''}) {

    const location = useLocation();
    const [register, setRegister] = useState({});
    const [loadCorrectly, setLoadCorrectly] = useState(false);
    const [arraySelects, setArraySelects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorsForm, setErrorsForm] = useState({});
    const [localProperties, setLocalProperties] = useState({properties: registerProperties});

    useEffect(() => {
        const prevRegisterId = location.state.id;
        PrepareRegisterObject(prevRegisterId);
    }, [moduleName]);

    const PrepareRegisterObject = async (register_id) => {
        let registerWithProps = {};
        let arrayToSelectedDefaults = []

        setLoading(true);
        const response = await apiBase.get(detailURL + register_id);

        if (response.ok) {

            for (let i = 0; i < localProperties.properties.length; i++) {
                let property = localProperties.properties[i];
                property.default = response.data[property.name];
                registerWithProps[property.name] = property.default;

                if (property.type === 'select' && property.urlItems) {
                    const response = await apiBase.get(property.urlItems);
                    if (response.ok) {
                        property.default = response.data?.find(x => x.value === property.default);
                        property.items = response.data;
                        arrayToSelectedDefaults.push(property);
                    }
                } else if (property.type === 'date') {
                    const prop = response.data[property.name];
                    if (prop) {
                        property.default = new Date(prop);
                    } else {
                        property.default = new Date();
                    }
                }
            }

            setLoadCorrectly(true);
            setLoading(false);
            setRegister(registerWithProps);
            setArraySelects(arrayToSelectedDefaults);
        } else {
            setLoadCorrectly(false);
            setLoading(false);
        }
    }

    const EditItem = async () => {
        register.id = location.state.id;

        setLoading(true);
        const response = await apiBase.put(editURL, register);
        setLoading(false);

        if (response.ok) {
            AppAlert.SimpleAsyncAlert(response.message).then(response => {
            });
        } else {
            setErrorsForm(response);
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text={`Edición de ${moduleName}:`}/>
                <BreadCrumb
                    items={[{label: `${moduleName}`}, {label: `Edición de ${moduleName}:`, active: true}]}/>
            </div>
            <div className="card col-12">
                <div className="row card-body col-12">
                    {
                        localProperties.properties.map((property, index) => {
                            if (property.type === 'input')
                                return (
                                    <div className={`col-${property.col ? property.col : 4}`} key={index}>
                                        <AppInput
                                            label={property.label}
                                            defaultValue={property.default}
                                            type={property.input_type}
                                            placeholder={property.placeholder ? property.placeholder : 'Ingrese ' + property.label?.toLowerCase()}
                                            onChange={({target}) => {
                                                register[property.name] = target.value;
                                                setRegister(register);
                                            }}
                                            name={property.default}
                                            errors={errorsForm[property.name]}
                                        />
                                    </div>
                                )
                            else if (property.type === 'date') {
                                return (
                                    <div className={`row col-${property.col ? property.col : 4}`} key={index}>
                                        <div className='col-12'>
                                            {/*<div className='col-6'>*/}
                                            <AppCalendar
                                                label={property.label}
                                                onChange={
                                                    (element) => {
                                                        let indexFounded = localProperties.properties.indexOf(property);
                                                        let elementFounded = localProperties.properties[indexFounded]
                                                        let dateWithFormat = helperDate.parseToServerFormat(element);
                                                        elementFounded.default = element;
                                                        localProperties.properties.splice(indexFounded, 1, elementFounded);
                                                        register[property.name] = dateWithFormat;
                                                        setRegister(register);
                                                        setLocalProperties({
                                                            ...localProperties,
                                                            properties: localProperties.properties
                                                        });
                                                    }
                                                }
                                                value={property.default}
                                                errors={errorsForm[property.name]}/>
                                        </div>
                                        {/*<div*/}
                                        {/*    className="form-check row d-flex col-6 justify-content-center align-items-center">*/}
                                        {/*    <input className="col-5 form-check-input" type="checkbox"*/}
                                        {/*           defaultChecked={property.hasDate}*/}
                                        {/*           id={property.name} onChange={() => {*/}
                                        {/*    }}/>*/}
                                        {/*    <label className="col-7 mt-2 form-check-label font-weight-bold"*/}
                                        {/*           htmlFor={property.name}>*/}
                                        {/*        <small>Considerar</small>*/}
                                        {/*    </label>*/}
                                        {/*</div>*/}
                                    </div>

                                )
                            }
                            // else if (property.type === 'select') {
                            //     return (
                            //         <div className={`col-${property.col ? property.col : 4}`} key={index}>
                            //             <AppSelect
                            //                 defaultValue={arraySelects.find(x => x.value === property.default?.value)}
                            //                 label={property.label}
                            //                 items={property.items ? property.items : []}
                            //                 errors={errorsForm[property.name]}
                            //                 onChange={(element) => {
                            //                     register[property.name] = element ? element.value : null;
                            //                     setRegister(register);
                            //                 }}
                            //             />
                            //
                            //             <div className='mb-2'>
                            //                 <label htmlFor="select">{property.label}</label>
                            //                 <Select
                            //                     // value={property.default}
                            //                     options={property.items ? property.items : []}
                            //                     onChange={(element) => {
                            //                         register[property.name] = element ? element.value : null;
                            //                         setRegister(register);
                            //                     }}
                            //                     defaultValue={property.default}
                            //                     // onInputChange={onInputChange}
                            //                     isClearable={true}
                            //                     maxMenuHeight={400}
                            //                 />
                            //                 {errorsForm[property.name]?.map((error, index) => {
                            //                     return (
                            //                         <span key={index} className="text-danger small">
                            //                             {error}
                            //                         </span>
                            //                     );
                            //                 })}
                            //             </div>
                            //         </div>
                            //     )
                            // }
                        })
                    }
                    {
                        arraySelects.map((item, index) => {
                            return (
                                <div className={`col-${item.col ? item.col : 4}`} key={index}>
                                    <AppSelect
                                        label={item.label}
                                        items={item.items ? item.items : []}
                                        defaultValue={item.default}
                                        errors={errorsForm[item.name]}
                                        onChange={(element) => {
                                            register[item.name] = element ? element.value : null;
                                            setRegister(register);
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                    {
                        loadCorrectly && (
                            <div className="col-12 text-center my-4">
                                <button className="btn btn-primary" onClick={EditItem}>
                                    Editar {moduleName}
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}

export default GenericEditFormScreen;
