import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import appColors from "../../config/appColors";
import AuthContext from "../../context/context";
import AppIcon from "../controls/AppIcon";
import AppAlert from "./AppAlert";

function Header({ menu = [], user = {} }) {
	const authContext = useContext(AuthContext);
	const history = useHistory();

	const Redirect = (route) => {
		history.push(route);
	};

	const CloseSession = () => {
		AppAlert.OptionsAlert(`Si cierra su sesión, el sistema le solicitará su password nuevamente al intentar ingresar mas adelante. \n  \t ¿ Desea cerrar su sesión ?`, 'Cerrar sesión', 'warning', '').then((accepted => {
			if (accepted) {
				history.push("/");
				localStorage.removeItem("userLogged");
				authContext.setUserLogged(null);
			}
		}));
	};

	return (
		<div className="d-flex justify-content-around bg-white shadow rounded pb-2 sticky-top row">
			<button className="btn text-primary mt-4" onClick={() => Redirect("/")}>
				<img src="../imgs/logo-VIC.jpg" style={{ width: 150, alignSelf: "center" }} className="" />
			</button>
			{menu.map((item, index) => {
				return (
					<div key={index} className="btn-group" role="group">
						<button id="btnGroupDrop1" className="btn" data-toggle="dropdown">
							<div className="row">
								<div className="col-md-12 ">
									<AppIcon color={'#3B4389'} iconName={item.icon} />
								</div>
								<div className="col-md-12">
									<span className="small" style={{color:'#3B4389'}}>{item.title}</span>
								</div>
							</div>
						</button>
						<div className="dropdown-menu" aria-labelledby="navbarDropdown">
							{item.submenu.map((submenu, index) => {
								return (
									<button key={index} className="btn" onClick={() => Redirect(submenu.route)}>
										<div className="row">
											<div className="col-md-12">
												<AppIcon color={'#3B4389'} iconName={submenu.icon} />
											</div>
											<div className="col-md-12">
												<span className="small" style={{color:'#3B4389'}}>{submenu.label}</span>
											</div>
										</div>
										<div className="dropdown-divider" />
									</button>
								);
							})}
						</div>
					</div>
				);
			})}
			<button className="btn" onClick={CloseSession}>
				<div className="row">
					<div className="col-md-12">
						<AppIcon iconName={"door-open"} color={'#ED3237'} />
					</div>
					<div className="col-md-12">
						<span className="small text-danger">Salir</span>
					</div>
				</div>
			</button>
		</div>
	);
}

export default Header;
