import axios from "axios";
import AppAlert from "../apps/common/AppAlert";

const baseUrl = "http://services.boticas-vicfarma.com/";
const baseInvoiceUrl = "http://159.223.120.186:1337/";
// const baseUrl = "http://127.0.0.1:8000/";

const CONNECTION_SERVER_ERROR = "Ocurrió un problema con la conexión al servidor.";
const ERROR_TYPE = "error";

function getUserToken() {
    const userInLocalStorage = localStorage.getItem("userLogged");

    if (userInLocalStorage) {
        const currentUser = JSON.parse(userInLocalStorage);
        const token = currentUser.token;
        return token;
    }
    return null;
}

axios.interceptors.request.use(function (config) {
    config.headers.Authorization = "Token " + getUserToken();
    return config;
});

export default {
    invoicePost(url, data) {
        return axios
            .post(baseInvoiceUrl + url, data)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.ok) {
                        return response.data;
                    } else {
                        AppAlert.SimpleAlert(response.data.message + ' EXCEPTION: ' + response.data.exception, ERROR_TYPE);
                        return {ok: false, message: response.data.message, exception: response.data.exception};
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status == 400) {
                    return error.response.data;
                } else {
                    AppAlert.SimpleAlert(CONNECTION_SERVER_ERROR, ERROR_TYPE);
                    return {
                        ok: false,
                        message: CONNECTION_SERVER_ERROR + ' EXCEPTION: ' + error.message,
                        exception: error.message
                    };
                }
            });
    },
    invoiceGet(url) {
        return axios
            .get(baseInvoiceUrl + url)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.ok) {
                        return response.data;
                    } else {
                        AppAlert.SimpleAlert(response.data.message + ' EXCEPTION: ' + response.data.exception, ERROR_TYPE);
                        return {ok: false, message: response.data.message, exception: response.data.exception};
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status == 400) {
                    return error.response.data;
                } else {
                    AppAlert.SimpleAlert(CONNECTION_SERVER_ERROR + ' EXCEPTION: ' + error.message, ERROR_TYPE);
                    return {ok: false, message: CONNECTION_SERVER_ERROR, exception: error.message};
                }
            });
    },
    post(url, data) {
        return axios
            .post(baseUrl + url, data)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.ok) {
                        return response.data;
                    } else {
                        AppAlert.SimpleAlert(response.data.message + ' EXCEPTION: ' + response.data.exception, ERROR_TYPE);
                        return {ok: false, message: response.data.message, exception: response.data.exception};
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status == 400) {
                    return error.response.data;
                } else {
                    AppAlert.SimpleAlert(CONNECTION_SERVER_ERROR, ERROR_TYPE);
                    return {
                        ok: false,
                        message: CONNECTION_SERVER_ERROR + ' EXCEPTION: ' + error.message,
                        exception: error.message
                    };
                }
            });
    },
    get(url) {
        return axios
            .get(baseUrl + url)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.ok) {
                        return response.data;
                    } else {
                        AppAlert.SimpleAlert(response.data.message + ' EXCEPTION: ' + response.data.exception, ERROR_TYPE);
                        return {ok: false, message: response.data.message, exception: response.data.exception};
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status == 400) {
                    return error.response.data;
                } else {
                    AppAlert.SimpleAlert(CONNECTION_SERVER_ERROR + ' EXCEPTION: ' + error.message, ERROR_TYPE);
                    return {ok: false, message: CONNECTION_SERVER_ERROR, exception: error.message};
                }
            });
    },
    put(url, data) {
        return axios
            .put(baseUrl + url, data)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.ok) {
                        return response.data;
                    } else {
                        AppAlert.SimpleAlert(response.data.message + ' EXCEPTION: ' + response.data.exception, ERROR_TYPE);
                        return {ok: false, message: response.data.message, exception: response.data.exception};
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status == 400) {
                    return error.response.data;
                } else {
                    AppAlert.SimpleAlert(CONNECTION_SERVER_ERROR, ERROR_TYPE);
                    return {
                        ok: false,
                        message: CONNECTION_SERVER_ERROR + ' EXCEPTION: ' + error.message,
                        exception: error.message
                    };
                }
            });
    },
    delete(url) {
        return axios
            .delete(baseUrl + url)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.ok) {
                        return response.data;
                    } else {
                        AppAlert.SimpleAlert(response.data.message, ERROR_TYPE);
                        return {ok: false, message: response.data.message, exception: response.data.exception};
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status == 400) {
                    return error.response.data;
                } else {
                    AppAlert.SimpleAlert(CONNECTION_SERVER_ERROR, ERROR_TYPE);
                    return {ok: false, message: CONNECTION_SERVER_ERROR, exception: error.message};
                }
            });
    },
    getAttachment(url, filename) {
        url = baseUrl + url;

        return axios({
            url,
            method: "GET",
            responseType: "blob",
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename ? filename : "Archivo sin nombre.txt");
                link.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                if (error.response && error.response.status == 400) {
                    return error.response.data;
                } else {
                    AppAlert.SimpleAlert(CONNECTION_SERVER_ERROR, ERROR_TYPE);
                    return {ok: false, message: CONNECTION_SERVER_ERROR};
                }
            });
    },
};
