import React, {useEffect, useState} from "react";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";
import apiBase from "../../../apis/apiBase";
import AppCalendar from "../../controls/AppCalendar";
import helperDate from "../../../helpers/helperDate";

function GenericCreateFormScreen({moduleName = 'Registro', registerProperties = [], createURL = '/'}) {

    const [register, setRegister] = useState({});

    const [loadCorrectly, setLoadCorrectly] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorsForm, setErrorsForm] = useState({});
    const [localProperties, setLocalProperties] = useState({properties: registerProperties, hasChange: false});

    useEffect(() => {
        PrepareRegisterObject(true);
    }, [moduleName]);

    const CleanRegisterObject = async () => {
       window.location.reload();
    }

    const PrepareRegisterObject = async (isFirstLoad = true) => {

        let registerWithProps = {};
        for (let i = 0; i < localProperties.properties.length; i++) {
            let property = localProperties.properties[i];
            registerWithProps[property.name] = register[property.name] ? register[property.name] : property.default;

            if (property.type === 'select' && property.urlItems && isFirstLoad) {

                setLoadCorrectly(false);
                const response = await apiBase.get(property.urlItems);

                if (response.ok) {
                    setLoadCorrectly(true);
                    registerWithProps[property.name + 'ItemsToSelect'] = response.data;
                }
            } else if (property.type === 'date') {
                registerWithProps[property.name] = register[property.name] ? helperDate.parseToServerFormat(register[property.name]) : helperDate.parseToServerFormat(property.default);
            } else {
                setLoadCorrectly(true);
            }
        }

        Object.getOwnPropertyNames(registerWithProps).map(propertyName => {
            if (register[propertyName])
                registerWithProps[propertyName] = register[propertyName];
        });

        setRegister(registerWithProps);
    }

    const CreateItem = async () => {
        setLoading(true);
        const response = await apiBase.post(createURL, register);
        setLoading(false);

        if (response.ok) {
            AppAlert.SimpleAsyncAlert(response.message).then((accept) => {
                setErrorsForm({});
                if (accept) {
                    CleanRegisterObject();
                }
            });
        } else {
            setErrorsForm(response);
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text={`Registro de ${moduleName}:`}/>
                <BreadCrumb
                    items={[{label: `${moduleName}`}, {label: `Registro de ${moduleName}:`, active: true}]}/>
            </div>
            <div className="card col-12">
                <div className="row card-body col-12">
                    {
                        localProperties.properties.map((property, index) => {
                            if (property.type === 'input')
                                return (
                                    <div className={`col-${property.col ? property.col : 4}`} key={index}>
                                        <AppInput label={property.label}
                                                  type={property.input_type}
                                                  defaultValue={register[property.name]}
                                                  placeholder={property.placeholder ? property.placeholder : 'Ingrese ' + property.label?.toLowerCase()}
                                                  onChange={({target}) => {
                                                      register[property.name] = target.value;
                                                      setRegister(register);
                                                  }}
                                                  errors={errorsForm[property.name]}
                                        />
                                    </div>
                                )
                            else if (property.type === 'select')
                                return (
                                    <div className={`col-${property.col ? property.col : 4}`} key={index}>
                                        <AppSelect items={register[property.name + "ItemsToSelect"]}
                                                   label={property.label}
                                                   placeholder={property.placeholder ? property.placeholder : 'Ingrese ' + property.label?.toLowerCase()}
                                                   onChange={(item) => {
                                                       register[property.name] = item ? item.value : null;
                                                       setRegister(register);
                                                   }}
                                                   errors={errorsForm[property.name]}
                                        />
                                    </div>
                                )
                            else if (property.type === 'date')
                                return (
                                    <AppCalendar
                                        key={index}
                                        label={property.label}
                                        onChange={
                                            (element) => {
                                                let indexFounded = localProperties.properties.indexOf(property);
                                                let elementFounded = localProperties.properties[indexFounded]
                                                let dateWithFormat = helperDate.parseToServerFormat(element);
                                                elementFounded.default = element;
                                                localProperties.properties.splice(indexFounded, 1, elementFounded);
                                                register[property.name] = dateWithFormat;
                                                setRegister(register);
                                                setLocalProperties({
                                                    ...localProperties,
                                                    properties: localProperties.properties
                                                });
                                            }
                                        }
                                        value={property.default}
                                        errors={errorsForm[property.name]}/>
                                )
                        })
                    }
                    {
                        loadCorrectly &&
                        <div className="col-12 text-center my-4">
                            <button className="btn btn-primary" onClick={() => CreateItem()}>
                                Registrar {moduleName}
                            </button>
                        </div>
                    }
                </div>
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}

export default GenericCreateFormScreen;
