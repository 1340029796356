import apiBase from "../apiBase";

export default {
	createUser(data) {
		const url = "api/person/user/create";
		return apiBase.post(url, data);
	},
	updateUser(data) {
		const url = "api/person/user/update";
		return apiBase.put(url, data);
	},
	getUsers(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/person/user/getUsersPaginated?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
	getUsersDropdown(page = 1, names = "") {
		if (page < 1) page = 1;
		const url = "api/person/user/getUsersDropdown?page=" + page + "&&names=" + names;
		return apiBase.get(url);
	},
};
