export default {
    person: {
        name: 'Cliente',
        allURL: 'api/person/getPersonsPaginated',
        createURL: 'api/person/create',
        deleteURL: 'api/person/delete/',
        updateURL: 'api/person/update',
        detailURL: 'api/person/details/',
        redirect: {
            editComponent: '/person/edit',
            createComponent: '/person/create'
        },
        table: {
            headers: ['Creado', 'Nombres', 'Apellidos', 'Dirección', 'Dni', 'Ruc', 'Teléfono', 'Código'],
            values: ['created', 'first_names', 'last_names', 'address', 'dni', 'ruc', 'phone', 'code'],
            buttons: ['edit', 'delete'],
        },
        properties:
            [
                {
                    label: 'Nombres',
                    name: 'first_names',
                    default: null,
                    type: 'input',
                    col: 6
                },
                {
                    label: 'Apellidos',
                    name: 'last_names',
                    default: null,
                    type: 'input',
                    col: 6
                },
                {
                    label: 'DNI',
                    name: 'dni',
                    default: null,
                    type: 'input',
                    col: 4
                },
                {
                    label: 'RUC',
                    name: 'ruc',
                    default: null,
                    type: 'input',
                    col: 4
                },
                {
                    label: 'Celular',
                    name: 'phone',
                    default: null,
                    type: 'input',
                    col: 4
                },
                // {
                //     label: 'Código',
                //     name: 'code',
                //     default: null,
                //     type: 'input',
                //     col: 4
                // },
                {
                    label: 'Dirección',
                    name: 'address',
                    default: null,
                    type: 'input',
                    col: 12
                }
            ]
    },
    user: {
        name: 'Usuario',
        allURL: 'api/person/user/getUsersPaginated',
        createURL: 'api/person/user/create',
        deleteURL: 'api/person/user/delete/',
        updateURL: 'api/person/user/update',
        detailURL: 'api/person/user/details/',
        redirect: {
            editComponent: '/user/edit',
            createComponent: '/user/create'
        },
        table: {
            headers: ['Email', 'Rol', 'Punto de Servicio'],
            values: ['email', 'rol', 'dinner_point'],
            buttons: ['edit', 'delete'],
        },
        properties:
            [
                {
                    label: 'Email',
                    name: 'email',
                    default: null,
                    type: 'input',
                    col: 4
                },
                {
                    label: 'Password',
                    name: 'password',
                    default: null,
                    type: 'input',
                    col: 4
                },
                {
                    label: 'Punto de Servicio',
                    name: 'dinner_point_id',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/dinner/dinnerPoint/getDinnerPointsDropdown'
                },
                {
                    label: 'Rol',
                    name: 'rol_id',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/person/rol/getRolsDropdown'
                },
            ]
    },
    product: {
        name: 'Producto',
        allURL: 'api/sale/product/getProductsPaginated',
        createURL: 'api/sale/product/create',
        deleteURL: 'api/sale/product/delete/',
        updateURL: 'api/sale/product/update',
        detailURL: 'api/sale/product/details/',
        redirect: {
            editComponent: '/product/edit',
            createComponent: '/product/create'
        },
        table: {
            headers: ['Creado', 'Nombre', 'Precio', 'Costo', 'Código', 'Stock', 'Expira', 'Alerta expira', 'Categoría', 'Presentación', 'Laboratorio', 'Sucursal'],
            values: ['created', 'name', 'price', 'cost', 'code', 'stock_alert', 'expire', 'expire_alert', 'category_name', 'presentation_name', 'laboratory_name', 'dinner_name'],
            buttons: ['edit', 'delete'],
        },
        properties:
            [
                {
                    label: 'Nombre',
                    name: 'name',
                    default: null,
                    type: 'input',
                    col: 6
                },
                {
                    label: 'Precio',
                    name: 'price',
                    default: null,
                    type: 'input',
                    input_type: 'number',
                    col: 3
                },
                {
                    label: 'Costo',
                    name: 'cost',
                    default: null,
                    type: 'input',
                    input_type: 'number',
                    col: 3
                },
                {
                    label: 'Código',
                    name: 'code',
                    default: null,
                    type: 'input',
                    col: 4
                },
                {
                    label: 'Lote',
                    name: 'lot',
                    default: null,
                    type: 'input',
                    col: 4
                },
                {
                    label: 'Medida',
                    name: 'measurer',
                    default: null,
                    type: 'input',
                    col: 4
                },
                {
                    label: 'Stock',
                    name: 'stock',
                    default: null,
                    input_type: 'number',
                    type: 'input',
                    col: 6
                },
                {
                    label: 'Fecha de Vencimiento',
                    name: 'expiration_date',
                    default: new Date(),
                    type: 'date',
                    col: 6
                },
                {
                    label: 'Categoría',
                    name: 'category_id',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/sale/category/getCategoriesDropdown'
                },
                {
                    label: 'Presentación',
                    name: 'presentation_id',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/sale/presentation/getPresentationsDropdown'
                },
                {
                    label: 'Laboratorio',
                    name: 'laboratory_id',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/sale/laboratory/getLaboratoriesDropdown'
                },
                {
                    label: 'Sucursal',
                    name: 'dinner_id',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/dinner/getDinnersDropdown'
                },
            ]
    },
    category_product: {
        name: 'Categoría',
        allURL: 'api/sale/category/getCategoriesPaginated',
        createURL: 'api/sale/category/create',
        deleteURL: 'api/sale/category/delete/',
        updateURL: 'api/sale/category/update',
        detailURL: 'api/sale/category/details/',
        redirect: {
            editComponent: '/category-product/edit',
            createComponent: '/category-product/create'
        },
        table: {
            headers: ['Creado', 'Nombre'],
            values: ['created', 'name'],
            buttons: ['edit', 'delete'],
        },
        properties:
            [
                {
                    label: 'Nombre',
                    name: 'name',
                    placeholder: 'Nombre',
                    default: null,
                    type: 'input',
                    col: 6
                },
                {
                    label: 'Compañía',
                    name: 'company_id',
                    placeholder: 'Compañía',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/company/getCompaniesDropdown'
                },
            ]
    },
    presentation_product: {
        name: 'Presentación',
        allURL: 'api/sale/presentation/getPresentationsPaginated',
        createURL: 'api/sale/presentation/create',
        deleteURL: 'api/sale/presentation/delete/',
        updateURL: 'api/sale/presentation/update',
        detailURL: 'api/sale/presentation/details/',
        redirect: {
            editComponent: '/presentation-product/edit',
            createComponent: '/presentation-product/create'
        },
        table: {
            headers: ['Creado', 'Nombre'],
            values: ['created', 'name'],
            buttons: ['edit', 'delete'],
        },
        properties:
            [
                {
                    label: 'Presentación',
                    name: 'name',
                    default: null,
                    type: 'input',
                    col: 6
                },
                {
                    label: 'Compañía',
                    name: 'company_id',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/company/getCompaniesDropdown'
                },
            ]
    },
    laboratory_product: {
        name: 'Laboratorio',
        allURL: 'api/sale/laboratory/getLaboratoriesPaginated',
        createURL: 'api/sale/laboratory/create',
        deleteURL: 'api/sale/laboratory/delete/',
        updateURL: 'api/sale/laboratory/update',
        detailURL: 'api/sale/laboratory/details/',
        redirect: {
            editComponent: '/laboratory-product/edit',
            createComponent: '/laboratory-product/create'
        },
        table: {
            headers: ['Creado', 'Nombre'],
            values: ['created', 'name'],
            buttons: ['edit', 'delete'],
        },
        properties:
            [
                {
                    label: 'Laboratorio',
                    name: 'name',
                    default: null,
                    type: 'input',
                    col: 6
                },
                {
                    label: 'Compañía',
                    name: 'company_id',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/company/getCompaniesDropdown'
                },
            ]
    },
    payment_types: {
        name: 'Tipos de Pago',
        allURL: 'api/sale/payment_type/getPaymentTypesPaginated',
        createURL: 'api/sale/payment_type/create',
        deleteURL: 'api/sale/payment_type/delete/',
        updateURL: 'api/sale/payment_type/update',
        detailURL: 'api/sale/payment_type/details/',
        redirect: {
            editComponent: '/payments/edit',
            createComponent: '/payments/create'
        },
        table: {
            headers: ['Creado', 'Nombre', 'Comisión'],
            values: ['created', 'name', 'tax'],
            buttons: ['edit', 'delete'],
        },
        properties:
            [
                {
                    label: 'Nombre',
                    name: 'name',
                    default: null,
                    type: 'input',
                    col: 6
                },
                {
                    label: 'Comisión',
                    name: 'tax',
                    default: null,
                    input_type: 'number',
                    type: 'input',
                    col: 6,
                },
            ]
    },
    dinner_point: {
        name: 'Punto de Servicio',
        allURL: 'api/dinner/dinnerPoint/getDinnerPointsPaginated',
        createURL: 'api/dinner/dinnerPoint/create',
        deleteURL: 'api/dinner/dinnerPoint/delete/',
        updateURL: 'api/dinner/dinnerPoint/edit',
        detailURL: 'api/dinner/dinnerPoint/details/',
        redirect: {
            editComponent: '/dinner-point/edit',
            createComponent: '/dinner-point/create'
        },
        table: {
            headers: ['Creado', 'Nombre', 'Sucursal'],
            values: ['created', 'name', 'dinner_name'],
            buttons: ['edit', 'delete'],
        },
        properties:
            [
                {
                    label: 'Nombre',
                    name: 'name',
                    default: null,
                    type: 'input',
                    col: 6
                },
                {
                    label: 'Sucursal',
                    name: 'dinner_id',
                    default: null,
                    type: 'select',
                    col: 6,
                    urlItems: 'api/dinner/getDinnersDropdown'
                },
            ]
    },
}
