import React from "react";
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

registerLocale("es", es);

function AppCalendarRange({label, onChange, startDate, endDate}) {
    return (
        <div>
            <label>
                <b>{label}</b>
            </label>
            <DatePicker startDate={startDate} endDate={endDate} onChange={onChange} locale="es" selectsRange inline/>
            <small>
                Del <b>{startDate && moment(startDate).format('DD/MM/YYYY')}</b> al <b>{endDate && moment(endDate).format('DD/MM/YYYY')}</b>.
            </small>
        </div>
    );
}

export default AppCalendarRange;
