import React from "react";

function Footer() {
	return (
		<nav className="navbar navbar-light justify-content-center bg-white shadow">
			<img src="../imgs/logo.png" style={{ width: 25, alignSelf: "center", marginRight: 10 }} className="card-img-top shadow rounded" />
			<small className="small text-secondary" style={{fontSize: 10}}>
				Copyright &copy; {new Date().getFullYear()} - Desarrollado por HBR Team<br /> Todos los derechos reservados.
			</small>
		</nav>
	);
}

export default Footer;
