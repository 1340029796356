import React, {useEffect, useRef, useState} from "react";
import AppTitle from "../../controls/AppTitle";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import {useLocation} from "react-router";
import apiSale from "../../../apis/sale/apiSale";
import PrintPaymentProofBoleta from "../forms/PrintPaymentProofBoleta";
import PrintPaymentProofFactura from "../forms/PrintPaymentProofFactura";
import {useReactToPrint} from "react-to-print";

function SaleDetailScreen() {
    const location = useLocation();

    const [sale, setSale] = useState({});
    const [user, setUser] = useState({});
    const [person, setPerson] = useState({});
    const [products, setProducts] = useState([]);
    const [invoice, setInvoice] = useState('');
    const [document, setDocument] = useState({});
    const [payment, setPayment] = useState({});
    const [date, setDate] = useState('');
    const [hour, setHour] = useState('');

    const componentRefBoleta = useRef();
    const componentRefFactura = useRef();

    useEffect(() => {
        const sale = location.state;
        GetSaleDetails(sale.id)
    }, []);

    const SendToPrintFormBoleta = useReactToPrint({
        content: () => componentRefBoleta.current,
    });

    const SendToPrintFormFactura = useReactToPrint({
        content: () => componentRefFactura.current,
    });

    const GetSaleDetails = async (id_sale) => {
        const response = await apiSale.detailedSale(id_sale);

        if (response.ok) {
            const sale = response.data;
            setSale(sale);

            let person = {
                first_names: sale[0].first_names,
                last_names: sale[0].last_names,
                dni: sale[0].dni,
                ruc: sale[0].ruc,
                address: sale[0].address
            }
            let products = sale.map(item => {
                return {
                    name: item.product,
                    quantity: item.quantity,
                    price: item.unit_price,
                    unit_price: item.unit_price,
                }
            });

            let user = {
                email: sale[0].user_email,
                dinner_name: sale[0].branch_name,
                dinner_code: sale[0].branch_ruc,
                dinner_address: sale[0].branch_address,
                dinner_phone: sale[0].branch_phone,
            }
            let invoice = sale[0].document
            let document = {
                label: sale[0].document_type
            }
            let date = sale[0].date;
            let hour = sale[0].time;
            let payment = {
                label: sale[0].payment
            }

            setPerson(person);
            setProducts(products);
            setUser(user);
            setInvoice(invoice);
            setDate(date);
            setHour(hour);
            setDocument(document);
            setPayment(payment)
        }
    }

    const Print = () => {
        let isBoleta = document.label === 'BOLETA';
        if (isBoleta) SendToPrintFormBoleta();
        else SendToPrintFormFactura();
    }

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text="Detalle de Ventas:"/>
                <AppBreadCrumb items={[{label: "Ventas"}, {label: "Detalle de Venta:", active: true}]}/>
            </div>
            <div className="col-12 ">
                <button className={'btn btn-sm'} onClick={Print}>DESCARGAR</button>
                <div style={{height: 1650}}>
                    {
                        document.label === 'BOLETA' ?
                        <PrintPaymentProofBoleta
                            person={person}
                            user={user}
                            products={products}
                            invoice={invoice}
                            document={document}
                            payment={payment}
                            date={date}
                            ref={componentRefBoleta}
                            hour={hour}
                        />
                        : <PrintPaymentProofFactura
                            person={person}
                            user={user}
                            products={products}
                            invoice={invoice}
                            document={document}
                            payment={payment}
                            ref={componentRefFactura}
                            date={date}
                            hour={hour}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default SaleDetailScreen;
