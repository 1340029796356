import React, {useEffect, useState} from "react";
import apiCompany from "../../../apis/company/apiCompany";
import apiDinner from "../../../apis/dinner/apiDinner";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppInput from "../../controls/AppInput";
import AppSelect from "../../controls/AppSelect";
import AppTitle from "../../controls/AppTitle";

function DinnerCreateForm() {
    const [dinner, setDinner] = useState({})
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [errorsForm, setErrorsForm] = useState({});

    const CreateDinner = async () => {
        const data = {
            name: dinner.name,
            address: dinner.address,
            code: dinner.code,
            phone: dinner.phone,
            company_id: dinner.company_id,
        };

        setLoading(true);
        const response = await apiDinner.createDinner(data);
        console.log(response)
        setLoading(false);

        if (response.ok) {
            AppAlert.SimpleAsyncAlert(response.message).then((response) => {
                if (response) window.location.reload();
            });
            setErrorsForm({});

        } else {
            setErrorsForm(response);
        }
    };

    const GetCompanies = async () => {
        const response = await apiCompany.getCompaniesDropdown();

        if (response.ok) {
            setCompanies(response.data);
        }
    };

    useEffect(() => {
        GetCompanies();
    }, []);

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text="Registro de Sucursal"/>
                <BreadCrumb items={[{label: "Sucursales"}, {label: "Registro de sucursales", active: true}]}/>
            </div>
            <div className="card col-12">
                <div className="row card-body col-12">
                    <div className="col-lg-12">
                        <AppInput
                            label="Nombre de la sucursal"
                            type="text"
                            onChange={({target}) => setDinner({...dinner, name: target.value})}
                            placeholder="Nombre de la sucursal"
                            errors={errorsForm.name}
                        />
                    </div>
                    <div className="col-lg-6">
                        <AppInput
                            label="Dirección"
                            type="text"
                            onChange={({target}) => setDinner({...dinner, address: target.value})}
                            placeholder="Dirección de la sucursal"
                            errors={errorsForm.address}
                        />
                    </div>
                    <div className="col-lg-3">
                        <AppInput
                            label="RUC:"
                            type="text"
                            onChange={({target}) => setDinner({...dinner, code: target.value})}
                            placeholder="RUC de la sucursal"
                            errors={errorsForm.code}
                        />
                    </div>
                    <div className="col-lg-3">
                        <AppInput
                            label="Teléfono"
                            type="number"
                            onChange={({target}) => setDinner({...dinner, phone: target.value})}
                            placeholder="Teléfono de la sucursal"
                            errors={errorsForm.phone}
                        />
                    </div>
                    <div className="col-lg-12">
                        <AppSelect label="Compañía" items={companies}
                                   onChange={(item) => setDinner({...dinner, company_id: item.value})}
                                   errors={errorsForm.company_id}/>
                    </div>
                    <div className="col-12 text-center my-4">
                        <button className="btn btn-primary" onClick={CreateDinner}>
                            Crear nuevo registro
                        </button>
                    </div>
                </div>
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}

export default DinnerCreateForm;
