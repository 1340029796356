import appColors from "../config/appColors";

const getRandomIndexBetween = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
}

export default {
    getRandomColor() {
        const colors = Object.getOwnPropertyNames(appColors);
        const selectedColors = colors.filter(color => color.toString().endsWith('graphics'))
        const lengthColors = selectedColors.length;
        const color = selectedColors[getRandomIndexBetween(0, lengthColors)];
        return appColors[color];
    },
};
