import React, {useEffect, useState} from "react";
import AppLoading from "../../common/AppLoading";
import AppTitle from "../../controls/AppTitle";
import BreadCrumb from "../../common/AppBreadCrumb";
import AppCalendarRange from "../../controls/AppCalendarRange";
import moment from "moment/moment";
import apiSale from "../../../apis/sale/apiSale";
import TableToExcel from "../../components/TableToExcel";

function DailyResumeScreen() {
    let init = moment().format('YYYY-MM-DD');
    let end = moment().format('YYYY-MM-DD');
    const [initDate, setInitDate] = useState(new Date(init));
    const [endDate, setEndDate] = useState(new Date(end));
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(false);

    const headers = ["Documento", "Monto"];
    const values = ["document", "amount"];

    useEffect(() => {
        GetAllRecords(init, end);
        return () => {
        };
    }, []);

    const GetAllRecords = async (start, end) => {
        const data = {
            initDate: start,
            endDate: end,
        };

        setLoading(true)
        let response = await apiSale.getSalesToCashDeskReport(1, data);
        setLoading(false)

        if (response.ok) {
            setRecords(response.data)
        }
    };

    const onChangeDates = async (changeDate) => {
        const [start, end] = changeDate;

        setEndDate(end);
        setInitDate(start);

        if (end) {
            const startFormatted = moment(start).format('YYYY-MM-DD')
            const endFormatted = moment(end).format('YYYY-MM-DD')
            await GetAllRecords(startFormatted, endFormatted)
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text="Reporte de Caja para el Usuario Logeado: "/>
                <BreadCrumb items={[{label: "Cajas"}, {label: "Reporte de Caja", active: true}]}/>
            </div>
            <div className="col-md-12 card">
                <div className="card-body row">
                    <div className="col-md-12 text-center my-5">
                        <AppCalendarRange
                            label="Rango de Caja: "
                            startDate={initDate}
                            endDate={endDate}
                            onChange={onChangeDates}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <AppTitle text={"Reporte: "}/>
                <TableToExcel
                    id='CashDesk'
                    headers={headers}
                    values={values}
                    rows={records}
                />
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}

export default DailyResumeScreen;
