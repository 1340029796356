import React, {useEffect, useState} from "react";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";
import AppLoading from "../../common/AppLoading";
import {Link, useHistory} from "react-router-dom";
import BreadCrumb from "../../common/AppBreadCrumb";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";
import apiBase from "../../../apis/apiBase";

function GenericListFormScreen({
                                   tableHeaders = [],
                                   tableValues = [],
                                   tableButtons = [],
                                   urlItems = '',
                                   urlDelete = '',
                                   routeEditComponent = '',
                                   routeCreateComponent = '',
                                   moduleName = '',
                                   showCreateButton = true
                               }) {

    const headers = tableHeaders;
    const values = tableValues;
    const [buttons, setButtons] = useState([]);


    const history = useHistory();

    const [filter, setFilter] = useState("");
    const [paginator, setPaginator] = useState({});
    const [registerList, setRegisterList] = useState([]);
    const [loading, setLoading] = useState(false);

    const GetPaginatedRegisters = async (page = 1) => {
        setLoading(true);
        let response = await apiBase.get(urlItems + "?page=" + page + "&&filters=" + filter + "&&names=" + filter);
        setLoading(false);

        if (response.ok) {
            setPaginator(response.data.paginator);
            setRegisterList(response.data.results);
        }
    };

    const EditSelectedItem = (item) => {
        history.push({pathname: routeEditComponent, state: item});
    };

    const ShowDeleteSelectedItem = (item) => {
        AppAlert.OptionsAlert(
            `Esta registro (${item.name}) se eliminará, y ya no estará disponible para el resto de operaciones, se eliminará toda su información y la información relacionada a esta.`,
            "Eliminar de todos modos"
        ).then((response) => {
            if (response) {
                DeleteItem(item.id);
            }
        });
    };

    const DeleteItem = async (item_id) => {

        setLoading(true);
        let response = await apiBase.delete(urlDelete + item_id);
        setLoading(false);

        if (response.ok) {
            AppAlert.SimpleAsyncAlert(response.message).then(response => {
                GetPaginatedRegisters();
            });
        }
    };

    const GetSelectedButtons = () => {
        let buttons = [];
        let defaultButtons = [
            {
                name: 'edit',
                onClick: (item) => EditSelectedItem(item),
                style: "btn",
                iconName: "pen",
                iconColor: appColors.green_04
            },
            {
                name: 'delete',
                onClick: (item) => ShowDeleteSelectedItem(item),
                style: "btn",
                iconName: "trash-alt",
                iconColor: appColors.red_04
            },
            {
                name: 'detail',
                onClick: (item) => ShowDeleteSelectedItem(item),
                style: "btn",
                iconName: "trash-alt",
                iconColor: appColors.red_04
            },
        ];

        tableButtons.forEach(button => {
            buttons.push(defaultButtons.find(x => x.name === button))
        });
        setButtons(buttons);
    }

    useEffect(() => {
        // window.location.reload()
        console.log(moduleName)
        GetSelectedButtons();
        GetPaginatedRegisters();
    }, [moduleName]);

    return (
        <div className="row">
            <div className="col-10">
                <AppTitle text={`Lista de ${moduleName} Registrados:`}/>
                <BreadCrumb
                    items={[{label: `${moduleName}`}, {label: `Lista de ${moduleName} Registrados`, active: true}]}/>
            </div>
            <div className="col-2 d-flex align-items-center">
                {
                    showCreateButton &&
                    <Link className="btn btn-sm btn-success" to={routeCreateComponent}>
                        Registrar
                    </Link>
                }
            </div>
            <div className="col-12">
                <TablePaginate
                    headers={headers}
                    buttons={buttons}
                    rows={registerList}
                    values={values}
                    onSearchApi={(page) => GetPaginatedRegisters(page)}
                    onChangeFilter={setFilter}
                    paginator={paginator}
                />
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}


export default GenericListFormScreen;
