import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faLock,
    faCode,
    faHighlighter,
    faHome,
    faStar,
    faCashRegister,
    faPlus,
    faTrashAlt,
    faPen,
    faFileInvoice,
    faConciergeBell,
    faUtensils,
    faUser,
    faHandsHelping,
    faBriefcase,
    faDoorOpen,
    faBan,
    faBusinessTime,
    faMale,
    faMapMarked,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faListUl,
    faAddressCard,
    faCarrot,
    faEnvelopeSquare,
    faDrumstickBite,
    faDownload,
    faCalendarPlus,
    faPrint,
    faCheckDouble,
    faAddressBook,
    faArrowDown,
    faArrowAltCircleDown,
    faWeight,
    faEnvelope,
    faEye,
    faCodeBranch,
    faAward,
    faCapsules,
    faUserNurse,
    faStethoscope,
    faArrowCircleLeft,
    faBoxOpen,
    faPumpMedical,
    faPowerOff,
    faVial,
    faChartLine,
    faChartPie,
    faChartBar,
    faChartArea,
    faCreditCard,
    faDollarSign,
    faSearchDollar,
    faFileExcel,
    faExclamationCircle, faTruckMoving
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faLock,
    faPrint,
    faCodeBranch,
    faSearchDollar,
    faCheckDouble,
    faHandsHelping,
    faEnvelope,
    faAddressBook,
    faTruckMoving,
    faDownload,
    faExclamationCircle,
    faWeight,
    faAward,
    faAngleDoubleLeft,
    faDrumstickBite,
    faArrowAltCircleDown,
    faEye,
    faArrowDown,
    faEnvelopeSquare,
    faCarrot,
    faBusinessTime,
    faCode,
    faBan,
    faArrowCircleLeft,
    faAddressCard,
    faMapMarked,
    faMale,
    faUtensils,
    faAngleDoubleRight,
    faConciergeBell,
    faFileInvoice,
    faPowerOff,
    faListUl,
    faHighlighter,
    faHome,
    faCalendarPlus,
    faStar,
    faCashRegister,
    faPlus,
    faTrashAlt,
    faPen,
    faUser,
    faDoorOpen,
    faBriefcase,
    faCodeBranch,
    faCapsules,
    faUserNurse,
    faStethoscope,
    faBoxOpen,
    faPumpMedical,
    faVial,
    faChartLine,
    faDollarSign,
    faChartPie,
    faChartBar,
    faChartArea,
    faCreditCard,
    faFileExcel
);
