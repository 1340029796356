import React, { useEffect, useState } from "react";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";
import AppLoading from "../../common/AppLoading";
import { Link, useHistory } from "react-router-dom";
import BreadCrumb from "../../common/AppBreadCrumb";
import apiContractor from "../../../apis/contractor/apiContractor";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";

function ListContractorsScreen() {
	const headers = ["Fecha", "..."];
	const values = ["created", "name"];
	const buttons = [
		{ onClick: (contractor) => EditSelectedContractor(contractor), style: "btn", iconName: "pen", iconColor: appColors.green_04 },
		{ onClick: (contractor) => ShowDeleteSelectedContractor(contractor), style: "btn", iconName: "trash-alt", iconColor: appColors.red_04 },
	];

	const history = useHistory();

	const [filter, setFilter] = useState("");
	const [paginator, setPaginator] = useState({});
	const [contratorList, setContratorList] = useState([]);
	const [loading, setLoading] = useState(false);

	const GetContrators = async (page = 1) => {
		setLoading(true);
		var response = await apiContractor.getContractorsPaginated(page, filter);
		setLoading(false);

		if (response.ok) {
			setPaginator(response.data.paginator);
			setContratorList(response.data.results);
		}
	};

	const EditSelectedContractor = (contractor) => {
		history.push({ pathname: "/contractor/edit", state: contractor });
	};

	const ShowDeleteSelectedContractor = (contractor) => {
		AppAlert.OptionsAlert(
			"Este ... se eliminará, y ya no estará disponible para el resto de operaciones, y se eliminará toda su información.",
			"Eliminar de todos modos"
		).then((response) => {
			if (response) {
				DeleteContractor(contractor.id);
			}
		});
	};

	const DeleteContractor = async (contractor_id) => {
		var response = await apiContractor.deleteContractor(contractor_id);

		if (response.ok) {
			AppAlert.SimpleAlert(response.message);
			GetContrators();
		}
	};
	useEffect(() => {
		GetContrators();
		return () => {};
	}, []);

	return (
		<div className="row">
			<div className="col-10">
				<AppTitle text="Lista de ... Registrados:" />
				<BreadCrumb items={[{ label: "..." }, { label: "Lista de ... Registrados", active: true }]} />
			</div>
			<div className="col-2 d-flex align-items-center">
				<Link className="btn btn-sm btn-success" to={"contractor/create"}>
					Registrar ...
				</Link>
			</div>
			<div className="col-12">
				<TablePaginate
					headers={headers}
					buttons={buttons}
					rows={contratorList}
					values={values}
					onSearchApi={(page) => GetContrators(page)}
					onChangeFilter={setFilter}
					paginator={paginator}
				/>
			</div>
			<AppLoading visible={loading} />
		</div>
	);
}

export default ListContractorsScreen;
