import apiBase from "../apiBase";

export default {
    getRols(page = 1) {
        if (page < 1) page = 1;
        const url = "api/person/rol/getRols";
        return apiBase.get(url);
    },
    getRolsDropdown(page = 1) {
        if (page < 1) page = 1;
        const url = "api/person/rol/getRolsDropdown";
        return apiBase.get(url);
    },
};
