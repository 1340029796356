import React from 'react';
import {PieChart, Pie, Cell, ResponsiveContainer, Legend} from 'recharts';
import helperColor from "../../../helpers/helperColor";
import CustomGraphicsParts from "./common/CustomGraphicsParts";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" fontSize={12} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default function AppPieGraphic({data = [], lines = [], paramsY = [0, 10000]}) {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
                <Legend content={CustomGraphicsParts.CustomizedLegendPieChart}/>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={index} fill={helperColor.getRandomColor()}/>
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}
