import React, {PureComponent} from 'react';
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import helperColor from "../../../helpers/helperColor";
import appColors from "../../../config/appColors";
import CustomGraphicsParts from "./common/CustomGraphicsParts";

function AppBarGraphic({data = [], lines = [], paramsY = [0, 10000]}) {
    return (
        <ResponsiveContainer>
            <BarChart
                width={500}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" tick={CustomGraphicsParts.AxisCustomX}/>
                <YAxis orientation="left" tick={CustomGraphicsParts.AxisCustomY} domain={paramsY} stroke="#8884d8"/>
                <Tooltip content={CustomGraphicsParts.CustomizedTooltip}/>
                <Legend content={CustomGraphicsParts.CustomizedLegend}/>
                {
                    lines.map((line, index) => {
                        return <Bar key={index} dataKey={line} fill={helperColor.getRandomColor()}
                                    label={CustomGraphicsParts.CustomizedLabel}/>
                    })
                }
            </BarChart>
        </ResponsiveContainer>
    );
}

export default AppBarGraphic;

