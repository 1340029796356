import React, {useEffect, useState} from "react";
import AppIcon from "../controls/AppIcon";
import appColors from "../../config/appColors";
import AppAlert from "../common/AppAlert";
import AppSelectFilter from "../controls/AppSelectFilter";

function TableToExcel({
                          id = 'table',
                          rows = [],
                          headers = [],
                          values = [],
                          filterBy = [],
                          buttons = [],
                          excelName = ""
                      }) {

    const [localRows, setLocalRows] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});
    const [filterElements, setFilterElements] = useState([]);

    useEffect(() => {
        setLocalRows(rows);
        GetSelectFilters(rows);
    }, [rows]);

    const RemoveDuplicates = (arr = []) => {
        let unique = [];
        for (let i = 0; i < arr.length; i++) {
            let exist = unique.some(x => x.value === arr[i].value);
            if (!exist) {
                unique.push(arr[i])
            }
        }
        return unique;
    }

    const ChangeElementsToFilter = (elements, column) => {
        let newFilter = activeFilters;
        newFilter[column] = elements.map(x => x.value);
        setActiveFilters(newFilter);

        let filters = ChangeFiltersFormat(newFilter);
        ApplyAccumulatedFilters(filters);
        // ApplyAccumulatedFilters(filters);
    }

    const ChangeFiltersFormat = (filter) => {
        let filtersName = Object.getOwnPropertyNames(filter);
        let newElements = [];

        filtersName.forEach((name) => {
            filter[name].forEach((item) => {
                newElements.push({label: name, value: item})
            })
        })
        return newElements;
    }

    const ApplyAccumulatedFilters = (filters) => {

        if (filters.length > 0) {
            let newElements = [];
            for (let i = 0; i < rows.length; i++) {
                let row = rows[i];
                let isCorrect = true;
                let counter = 0;

                for (let j = 0; j < filters.length; j++) {
                    let filter = filters[j];
                    let prevFilter = filters[(j === 0) ? j : j - 1]

                    if (filter.label === prevFilter.label) {
                        if (row[filter.label] === filter.value) {
                            counter++;
                            isCorrect = true
                        } else {
                            if (counter === 0)
                                isCorrect = false;
                        }
                    } else {
                        if (row[filter.label] !== filter.value) {
                            counter = 0;
                            isCorrect = false;
                        }
                    }
                }

                if (isCorrect)
                    newElements.push(row);

                setLocalRows(newElements);
            }
        } else {
            setLocalRows(rows);
        }
    }

    const GetSelectFilters = (rows) => {
        let localValues = [];
        for (let i = 0; i < filterBy.length; i++) {
            let filter = filterBy[i];
            let element = {
                value: filter.value,
                label: filter.label,
                items: rows.map(row => {
                    return {label: row[filter.value], value: row[filter.value]};
                })
            }
            element.items = RemoveDuplicates(element.items);
            localValues.push(element);
        }
        setFilterElements(localValues)
    }

    const ExportTableToExcel = (tableID) => {
        try {
            let downloadLink;
            let dataType = 'application/vnd.ms-excel';
            let tableSelect = document.getElementById(tableID);
            let tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

            let filename = excelName ? excelName + '.xls' : 'report.xls';
            downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            if (navigator.msSaveOrOpenBlob) {
                let blob = new Blob(['\ufeff', tableHTML], {
                    type: dataType
                });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
                downloadLink.download = filename;
                downloadLink.click();
            }
        } catch (e) {
            AppAlert.AlertTopLeft('Ocurrió un problema ' + e.toString(), 'error');
        }
    }

    return (
        <div>
            <div className='p-2 text-right'>
                <AppIcon iconName='file-excel' size='lg' color={appColors.green_02} onClick={() => {
                    ExportTableToExcel(id)
                }}/>
            </div>
            <div className='col-12 row' style={{position: "relative", zIndex: 1}}>
                {
                    filterElements.map((filter, index) => {
                        return <div key={index} className={'col-4'}>
                            <AppSelectFilter
                                placeholder={filter.label}
                                label={filter.label}
                                items={filter.items}
                                isMulti={true}
                                onChange={(item) => ChangeElementsToFilter(item, filter.value)}
                            />
                        </div>
                    })
                }
            </div>
            <div className="table-responsive"
                 style={{maxHeight: 500, overflowY: "scroll", zIndex: 0, position: "relative"}}>
                <table id={id} className="table table-hover table-bordered table-striped" style={{fontSize: 12}}>
                    <thead className="thead-dark sticky-top" style={{fontWeight: 600}}>
                    <tr>
                        {headers.map((header, index) => {
                            return (
                                <th className="text-center" scope="col" key={index}>
                                    <span>{header.toString().toUpperCase()}</span>
                                </th>
                            );
                        })}
                        <th className={"text-center"} scope="col">
                            <span>OPCIONES</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {localRows.map((item, indexItem) => {
                        return (
                            <tr key={indexItem}>
                                {values.map((value, indexValue) => {
                                    if (typeof item[value] !== 'object')
                                        return (
                                            <td scope="row" key={indexValue}>
                                                <small>{item[value]}</small>
                                            </td>
                                        );
                                    else if (typeof item[value] === 'object')
                                        return (<td scope="row" key={indexValue}
                                                    style={{backgroundColor: item[value]?.backgroundColor}}>
                                            <small>{item[value]?.value}</small>
                                        </td>);
                                })}
                                <td className="text-center" scope="row">
                                    {buttons.length !== 0 ? (
                                        buttons.map((button, index) => {
                                            return (
                                                <button key={index} className={button.style}
                                                        onClick={() => button.onClick(item)}>
                                                    {button.iconName &&
                                                        <AppIcon iconName={button.iconName} color={button.iconColor}/>}
                                                    {button.text && <small>{button.text}</small>}
                                                </button>
                                            );
                                        })
                                    ) : (
                                        <span className="small">No disponible</span>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <div className='p-2'>
                    <span style={{
                        color: appColors.black_01,
                        fontWeight: 600,
                        fontSize: 12
                    }}>Elementos totales: {localRows.length} registros.</span>
            </div>
        </div>
    );
}

export default TableToExcel;
