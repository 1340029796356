import React, {useEffect, useState} from "react";
import {
    CartesianGrid,
    AreaChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Area
} from "recharts";
import helperColor from "../../../helpers/helperColor";
import CustomGraphicsParts from "./common/CustomGraphicsParts";

function    AppAreaGraphic({data = [], paramsY = [0, 10000], lines = []}) {

    const [areas, setAreas] = useState([]);

    useEffect(() => {
        const areasFormatted = lines.map((line) => {
            return {
                name: line,
                idName: 'color' + line,
                color: helperColor.getRandomColor(),
            }
        })
        setAreas(areasFormatted);
    }, [lines])

    return (
        <ResponsiveContainer>
            <AreaChart width={730} height={250} data={data}
                       margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                <defs>
                    {
                        areas.map((area, index) => {
                            return <linearGradient key={index} id={area.idName} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={area.color} stopOpacity={0.8}/>
                                <stop offset="95%" stopColor={area.color} stopOpacity={0}/>
                            </linearGradient>
                        })
                    }
                </defs>
                <XAxis dataKey="name" tick={CustomGraphicsParts.AxisCustomX}/>
                <YAxis tick={CustomGraphicsParts.AxisCustomY}/>
                <CartesianGrid strokeDasharray="3 3"/>
                <Tooltip content={CustomGraphicsParts.CustomizedTooltip}/>
                <Legend content={CustomGraphicsParts.CustomizedLegend}/>
                {
                    areas.map((area, index) => {
                        return <Area type="monotone" key={index} dataKey={area.name} stroke={area.color} fillOpacity={1}
                                     fill={`url(#${area.idName})`}  label={CustomGraphicsParts.CustomizedLabel}/>
                    })
                }
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default AppAreaGraphic;
