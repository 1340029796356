import Swal from "sweetalert2";

const ALERT_TITLE = "Notificacion HBR";
const DEFAULT_TYPE = "success";
const DEFAULT_WARNING_TYPE = "question";
const ACCEPT_BUTTON = "Aceptar";
const CANCELL_BUTTON = "Cancelar";

export default {
    SimpleAlert(message, type = DEFAULT_TYPE) {
        let title = "LO SENTIMOS !!!";

        if (type === "success")
            title = "PERFECTO !!!";


        Swal.fire({
            title: title,
            text: message,
            icon: type,
            confirmButtonText: ACCEPT_BUTTON,
        });
    },

    AlertTopLeft(message, type = DEFAULT_TYPE) {
        var title = "LO SENTIMOS !!!";

        if (type === "success") {
            title = "PERFECTO !!!";
        }

        Swal.fire({
            position: "top-end",
            icon: type,
            title: message,
            showConfirmButton: false,
            timer: 1000,
        });
    },

    OptionsAlert(message, acceptButtonText = ACCEPT_BUTTON, type = DEFAULT_WARNING_TYPE) {
        let title = "¿ ESTÁ SEGURO ?";

        if (type === "question") {
            title = "¿ ESTÁ SEGURO ?";
        }
        return Swal.fire({
            title: title,
            text: message,
            icon: type,
            confirmButtonText: acceptButtonText,
            showCancelButton: true,
            cancelButtonText: CANCELL_BUTTON,
        }).then((result) => {
            if (result) return result.isConfirmed;
            else return result.isConfirmed;
        });
    },

    SimpleAsyncAlert(message, acceptButtonText = ACCEPT_BUTTON, type = DEFAULT_TYPE) {
        let title = "LO SENTIMOS !!!";

        if (type === "success")
            title = "PERFECTO !!!";

        return Swal.fire({
            title: title,
            text: message,
            icon: type,
            confirmButtonText: acceptButtonText,
        }).then((result) => {
            if (result) return result.isConfirmed;
            else return result.isConfirmed;
        });
    },
};
