import React, {useEffect, useState} from "react";
import AppAlert from "../../common/AppAlert";
import AppLoading from "../../common/AppLoading";
import AppTitle from "../../controls/AppTitle";
import AppBreadCrumb from "../../common/AppBreadCrumb";
import apiProduct from "../../../apis/product/apiProduct";
import AppSubTitle from "../../controls/AppSubTitle";
import helperSession from "../../../helpers/helperSession";
import {useHistory, useLocation} from "react-router";
import moment from "moment/moment";
import apiDinner from "../../../apis/dinner/apiDinner";
import AppInput from "../../controls/AppInput";
import AppIcon from "../../controls/AppIcon";

function SaleEditScreen() {
    const history = useHistory();
    const location = useLocation();

    const relocationStates =
        [{label: 'Iniciado', value: '1'}, {label: 'En traslado', value: '2'}, {label: 'Finalizado', value: '3'}]
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [branches, setBranches] = useState([]);
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [referenceCode, setReferenceCode] = useState('');
    const [receiptBranch, setReceiptBranch] = useState({});
    const [relocationState, setRelocationState] = useState({});
    const [productListSelected, setProductListSelected] = useState({items: []});

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        (async () => {
            const relocation = location.state;
            let products = await GetAllProducts();
            await GetBranches();
            await GetRelocationDetail(relocation.id, products)
        })()
        return () => {
        };
    }, []);

    const GetRelocationDetail = async (relocation_id, products_items = []) => {
        const response = await apiProduct.getRelocationDetail(relocation_id);

        if (response.ok) {
            const data = response.data
            setReferenceCode(data?.relocation.code);

            if (data?.relocation.end_date)
                setEndDate(data?.relocation.end_date);

            setRelocationState(relocationStates.find(x => x.value === data?.relocation.state));
            setReceiptBranch({label: data?.relocation?.receipt_d_name, value: data?.relocation?.receipt_d_id})

            let products = []
            let total_quantity = 0;
            for (let i = 0; i < data?.details.length; i++) {
                let detail = data?.details[i];
                let found = products_items.find(x => x.value === detail.product_id)
                found.quantity = detail.quantity;
                total_quantity += parseInt(detail.quantity)
                products.push(found)
            }
            setTotalQuantity(total_quantity)
            setProductListSelected({items: products})
        }
    }

    const GetAllProducts = async () => {
        let response = await apiProduct.getProductsWithStockDropdown();

        if (response.ok) {
            return response.data;
        } else {
            return [];
        }
    };

    const GetBranches = async () => {
        let response = await apiDinner.getDinnersDropdown();
        if (response.ok) {
            setBranches(response.data);
        }
    };

    const CreateRelocation = async () => {

        const relocation = {
            id: location.state.id,
            end_date: moment(endDate).format('YYYY-MM-DD'),
            sender_user_id: helperSession.getCurrentUser().id,
            products: productListSelected.items.map(prd => ({
                id: prd.value,
                name: prd.label,
                quantity: prd.quantity,
                stock: 0,
                price: 0
            })),
            receipt_branch: receiptBranch.value,
            state: '3',
        };

        setLoading(true);
        const response = await apiProduct.finishRelocation(relocation);
        setLoading(false);

        if (response.ok) {
            AppAlert.AlertTopLeft(`${response.message}`, 'success');
            history.goBack()
        } else {
            setErrors(response);
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <AppTitle text="Finalizar traslado:"/>
                <AppBreadCrumb items={[{label: "Traslados"}, {label: "Edición de Traslado:", active: true}]}/>
            </div>
            <div className='row col-12 justify-content-center align-items-center'>
                <div className="col-md-5 shadow p-4">
                    <AppInput
                        label='Referencia:'
                        placeholder='Código de referencia...'
                        name='reference'
                        value={referenceCode}
                        disabled
                        onChange={({target}) => setReferenceCode(target.value)}
                        errors={errors.reference_code}
                    />
                </div>

                <div className="col-md-5 shadow p-4 mx-2">
                    <AppInput
                        label='Para almacén:'
                        placeholder='Almacén ...'
                        name='almacen'
                        value={receiptBranch && receiptBranch?.label}
                        disabled
                    />
                </div>
            </div>

            <div className='row col-12 justify-content-center align-items-center mt-2'>
                <div className="col-md-6 shadow p-4">
                    <AppInput
                        label='Estado Actual:'
                        placeholder='Estado ...'
                        name='state'
                        value={relocationState && relocationState?.label}
                        disabled
                    />
                </div>
                <div className="col-md-5 shadow p-4 mx-2">
                    {
                        ((relocationState?.value === '1') || (relocationState?.value === '2')) ?
                            <AppInput
                                label='Fin de traslado:'
                                type='date'
                                defaultValue={endDate}
                                onChange={({target}) => setEndDate(moment(target.value).format('YYYY-MM-DD'))} errors={errors.end_date}
                            />
                            :
                            <AppInput
                                label='Fin de traslado:'
                                placeholder='Fin traslado...'
                                name='end_date'
                                value={relocationState && relocationState?.label}
                                disabled
                            />
                    }

                </div>
            </div>

            {/* ************ PRODUCTS ************/}
            <div className="col-12 row shadow">
                <div className="row card-body justify-content-center align-items-center">
                    <div className='col-12 flex-grow-1 mb-2'>
                        <AppSubTitle text="PRODUCTOS TRASLADADOS: "/>
                    </div>

                    <div className="row col-12">
                        <div className="px-4 col-md-12 shadow">
                            <div className="table-sm table-response-md">
                                <table className="table w-100 table-sm small">
                                    <thead className="table-info">
                                    <tr>
                                        <th>Producto</th>
                                        <th>Cantidad</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {productListSelected.items.map((product, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{maxWidth: '50%', fontSize: 12}}>{product.label}</td>
                                                <td style={{width: 100, fontSize: 12}}>
                                                    <input
                                                        style={{maxWidth: 50, fontSize: 11, textAlign: 'center'}}
                                                        type="number"
                                                        disabled
                                                        readOnly
                                                        value={product.quantity}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td style={{maxWidth: '50%', fontSize: 14, fontWeight: 'bold'}}>Productos
                                            trasladados:
                                        </td>
                                        <td style={{width: 100, fontSize: 12, fontWeight: 'bold'}}>
                                            {totalQuantity} unidades.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr/>
                            {
                                ((relocationState?.value === '1') || (relocationState?.value === '2')) &&
                                <div className="text-center w-100 p-4">
                                    <button className="btn btn-primary btn-sm p-2" onClick={CreateRelocation}>
                                        <div className='row px-3'>
                                            <span>FINALIZAR TRASLADO - </span>
                                            <AppIcon iconName='truck-moving' color='white'></AppIcon>
                                        </div>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}

export default SaleEditScreen;
