import React, {useEffect, useState} from "react";
import TablePaginate from "../../components/TablePaginate";
import AppTitle from "../../controls/AppTitle";
import AppLoading from "../../common/AppLoading";
import {useHistory} from "react-router-dom";
import BreadCrumb from "../../common/AppBreadCrumb";
import appColors from "../../../config/appColors";
import AppAlert from "../../common/AppAlert";
import apiSale from "../../../apis/sale/apiSale";
import apiBase from "../../../apis/apiBase";

function ListSaleScreen() {
    const headers = ["Referencia", "Estado", "Inicio traslado", "Responsable Envío", "Lugar Inicio", "Fin Traslado", "Lugar Destino"];
    const values = ["code", "state_name", "init_date", "sender_user", "sender_dinner", "end_date",  "receipt_dinner"];
    const buttons = [
        {onClick: (sale) => EditSelected(sale), style: "btn", iconName: "lock", iconColor: appColors.blue_04},
        // {
        //     onClick: (sale) => ShowDeleteSelectedSale(sale),
        //     style: "btn",
        //     iconName: "trash-alt",
        //     iconColor: appColors.red_04
        // },
    ];

    const history = useHistory();
    const [filter, setFilter] = useState("");
    const [paginator, setPaginator] = useState({});
    const [saleList, setSaleList] = useState([]);
    const [loading, setLoading] = useState(false);

    const GetSales = async (page = 1) => {
        setLoading(true);
        const response = await apiBase.get(`api/relocation/GetRelocationsPaginated?page=${page}&&filters=${'filters'}`);
        setLoading(false);

        if (response.ok) {
            let data = [];

            for (let i = 0; i < response.data.results.length; i++) {
                let item = response.data.results[i];
                item.state_name = getStateByCode(item.state)
                data.push(item);
            }

            setPaginator(response.data.paginator);
            setSaleList(data);
        }
    };

    const getStateByCode = (state_id) => {
        switch (state_id) {
            case "1":
                return 'Iniciado';
            case "2":
                return 'En traslado';
            case "3":
                return 'Finalizado';
            default:
                break;
        }
    }

    const EditSelected = (relocation) => {
        history.push({pathname: "/relocation/edit", state: relocation});
    };

    const DetailSelectedSale = (sale) => {
        history.push({pathname: "/sale/detail", state: sale});
    };

    const ShowDeleteSelectedSale = (sale) => {
        AppAlert.OptionsAlert(
            "Esta venta se eliminará, y ya no estará disponible para el resto de operaciones, y se eliminará toda su información.",
            "Eliminar de todos modos"
        ).then((response) => {
            if (response) {
                DeleteSale(sale.id);
            }
        });
    };

    const ShowCancelSelectedSale = (sale) => {
        AppAlert.OptionsAlert(
            "Esta venta se anulará, el stock de los productos regresará y la venta queda en estado anulado.",
            "Anular de todos modos"
        ).then((response) => {
            if (response) {
                CancelSale(sale.id);
            }
        });
    };

    const CancelSale = async (sale_id) => {
        let response = await apiSale.cancelSale(sale_id);

        if (response.ok) {
            AppAlert.SimpleAlert(response.message);
            GetSales();
        }
    };

    const DeleteSale = async (sale_id) => {
        let response = await apiSale.deleteSale(sale_id);

        if (response.ok) {
            AppAlert.SimpleAlert(response.message);
            GetSales();
        }
    };

    useEffect(() => {
        GetSales();
        return () => {
        };
    }, []);

    return (
        <div className="row">
            <div className="col-10">
                <AppTitle text="Lista de Traslados Registrados:"/>
                <BreadCrumb items={[{label: "Traslados"}, {label: "Lista de traslados", active: true}]}/>
            </div>
            <div className="col-12">
                <TablePaginate
                    headers={headers}
                    buttons={buttons}
                    rows={saleList}
                    values={values}
                    onSearchApi={(page) => GetSales(page)}
                    onChangeFilter={setFilter}
                    paginator={paginator}
                />
            </div>
            <AppLoading visible={loading}/>
        </div>
    );
}

export default ListSaleScreen;
